import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import './assets/styles/main.css';

import ElementPlus, { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/styles/base.css';
import { createI18n } from 'vue-i18n';
import i18nConfig from './i18n/i18n';

import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import VConsole from 'vconsole';

// import 'vue-cropper/dist/index.css';
// import { VueCropper } from 'vue-cropper';
import VueClipboard from 'vue-clipboard2';



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faUser } from    '@fortawesome/free-solid-svg-icons';
import { faClipboard } from  '@fortawesome/free-solid-svg-icons';
import { faCalculator} from  '@fortawesome/free-solid-svg-icons';
import { faLink } from  '@fortawesome/free-solid-svg-icons';
import {faGear} from  '@fortawesome/free-solid-svg-icons';
import {faWallet} from  '@fortawesome/free-solid-svg-icons';
VueClipboard.config.autoSetContainer = true

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

const i18n = createI18n(i18nConfig)
app.use(i18n)
app.use(router)
// app.use(VueCropper)
app.use(ElementPlus, {
  zIndex: 2030
})

app.use(VueClipboard)

/* add icons to the library */
library.add(faTwitter, faLinkedin, faInstagram, faTelegram,faYoutube, faUser, faClipboard, faLink, faCalculator,faWallet , faGear, faCheck,faQuestion);
app.component('font-awesome-icon', FontAwesomeIcon)


app.mount('#app')


const urlParams = new URLSearchParams(location.search);
const isDebug = urlParams.get('debug');
if (isDebug) {
  const vConsole = new VConsole();
}