<template>
    <div v-if="metaConnected" class="relative sm:mr-10">
        <div @click="toggleWallet($event)"
            class=" rounded-3xl p-1 cursor-pointer flex flex-row gap-4  w-full h-10 text-[8px] lg:text-[12px] ">
            <div class="  container  rounded-full  w-12  flex justify-center items-center">
                <img src="@/assets/images/metamaskIcon.png" class="rounded-full w-6 h-6 flex" alt="compverse" />
            </div>
            <div class=" flex w-10/12 justify-center items-center">{{ account }}</div>
            <div class=" flex w-2/12 justify-center items-center">

            </div>
        </div>




        <div v-if="showDropdown"
            class="absolute sm:top-14 sm:right-0 right-0  border w-auto  sm:w-[22rem] h-auto bg-white shadow-md rounded-xl px-6 closeDropDownOnClick">
            <div class="font-bold text-[.85rem] sm:text-[1.5rem] my-1 sm:my-2">Wallet</div>
            <div class="flex"></div>
            <ul class="divide-y divide-gray-200">

                <div class=" px-1 py-2 flex flex-row justify-between w-full">
                    <div class="flex  w-2/12 hidden sm:block"> <img src="@/assets/images/cpv.png" class="w-8"
                            alt="compverse" />
                    </div>
                    <div class="flex  w-10/12 justify-start mt-2 md:mt-0  text-[.9rem]">{{ account }}</div>
                    <div class="flex w-2/12 font-bold text-[#54c49e] cursor-pointer mt-2 md:mt-0  text-[.9rem]"
                        @click="copyCPVAddress">Copy</div>
                </div>
                <div class="p-2 flex flex-row justify-between w-full">
                    <div class="flex  w-2/12 hidden sm:block"> <img src="@/assets/images/icon-filecoin.svg" class="w-6"
                            alt="filecoin" />
                    </div>
                    <div class="flex  w-10/12 justify-start mt-2 md:mt-0  text-[.9rem]">{{ f4Account }}</div>
                    <div class="flex w-2/12 font-bold text-[#54c49e] cursor-pointer mt-2 md:mt-0  text-[.9rem]"
                        @click="copyFilAddress">Copy</div>
                </div>
                <div class="flex flex-col">
                    <div class="font-bold text-[.85rem] sm:text-[1.5rem] my-1 sm:my-2">
                        Balances
                    </div>
                    <div class="p-2 flex flex-row justify-between w-full">
                        <div class="flex  w-2/12"> <img src="@/assets/images/filecoin-icon.svg" class="w-6 mt-2 sm:mt-0"
                                alt="compverse" />
                        </div>
                        <div class="flex  w-10/12 justify-start mt-2 md:mt-0  text-[.9rem]">{{ walletBalance }} FIL</div>

                    </div>
                    <div class="p-2 flex flex-row justify-between w-full">
                        <div class="flex  w-2/12"> <img src="@/assets/images/icon-efil.png" class="w-6 h-6 mt-2 sm:mt-0"
                                alt="compverse" />
                        </div>
                        <div class="flex  w-10/12 justify-start  mt-2 md:mt-0  text-[.9rem]">{{ efilTokenBalance }} eFIL
                        </div>

                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="font-bold text-[.85rem] sm:text-[1.5rem] my-1 sm:my-2">
                        Current Earnings
                    </div>
                    <div class="p-2 flex flex-row justify-between items-center w-full">
                        <div class="flex  w-2/12">
                            <img src="@/assets/images/walletIcon.svg" class="w-6 h-6 sm:w-7 sm:h-7 mt-2 sm:mt-0 "
                                alt="Wallet Icon" />

                        </div>
                        <div class="flex  w-10/12 justify-start  mt-2 md:mt-0  text-[.9rem]">{{ totalCurrentRewards }} eFIL
                        </div>

                    </div>
                </div>
                <div class="flex flex-col">
                    <div class="font-bold text-[.85rem] sm:text-[1.5rem] my-1 sm:my-2">
                        Historical Total Earnings
                    </div>
                    <div class="p-2 flex flex-row justify-between items-center w-full">
                        <div class="flex  w-2/12">
                            <img src="@/assets/images/walletIcon.svg" class="w-6 h-6 sm:w-7 sm:h-7 mt-2 sm:mt-0 "
                                alt="Wallet Icon" />

                        </div>
                        <div class="flex  w-10/12 justify-start  mt-2 md:mt-0  text-[.9rem]">{{ totalearnedRewards }} eFIL
                        </div>

                    </div>
                </div>
            </ul>
        </div>

    </div>
</template>

<script lang="ts">
import { $context, $user } from '@/store';
import fakeLockABI from "@/assets/fakelockabi.js";
import BigNumber from "bignumber.js";
import { ElMessage } from "element-plus";
import { ethers } from "ethers";
import efilecoinABI from "@/assets/efilecoinABI.js";
import Web3 from "web3";
export default {
    name: "WalletDropDown",
    props: {
        result: {
            type: Boolean,
        },
    },
    data() {
        return {
            totalCurrentRewards: 0,
            metaConnected: false,
            f4Account: "",
            f4FullAccount: "",
            account: null,
            earnedRewards: [],
            totalearnedRewards: 0,
            showWallet: false,
            userDeposits: [],
            showDropdown: false,
            fullAccount: null,
            walletBalance: 0,
            efilTokenBalance: 0,
            $context,
            $user,
            lockupContractAddress: "0x5Ebc21fCCc0b27F1AD3e57260f6990fEFdc6a389",
        };
    },
    mounted() {
        if ((this as any).result) {
            (this as any).showWallet = true;
        }
        document.addEventListener("click", (this as any).closeDropdownOnClickOutside);
        setTimeout(async () => {

            (this as any).calculateTotalStakeHoldings();

            (this as any).fetchAddressInfo();


        }, 1000); // Adjust the delay as needed
        (this as any).getAccountInfo();
        (this as any).getWalletBalance();
        (this as any).getEFILTokenBalance();


    }, beforeDestroy() {
        document.removeEventListener("click", (this as any).closeDropdownOnClickOutside);
    },
    watch: {
        totalearnedRewards(newValue) {
            if (newValue) {

                // Calculate the tax amount (1% of stakeAmount)
                (this as any).totalearnedRewards = newValue


            }
        },

    },
    methods: {

        closeDropdownOnClickOutside(event) {
            // @ts-ignore
            const dropdown = this.$el.querySelector(".closeDropDownOnClick"); // Add a dot before the class name
            if ((this as any).showDropdown && dropdown && !dropdown.contains(event.target)) {
                (this as any).showDropdown = false;
            }
        },
        async fetchAddressInfo() {
            const address = (this as any).fullAccount; // Replace with your dynamic address
            fetch(`https://staking-rewards-connector-32302755e2a7.herokuapp.com/api/getAddressInfo?address=${address}`)
                .then(response => response.json())
                .then(data => {
                    (this as any).f4FullAccount = data.address.split(' ')[1];
                    (this as any).f4Account = (this as any).f4FullAccount.slice(0, 6) + "..." + (this as any).f4FullAccount.slice(-6);
                    // Process the data here
                })
                .catch(error => {
                    console.error(error);
                });
        },



        async calculateTotalStakeHoldings() {
            try {
                // Initialize Web3 and the smart contract as needed
                const web3 = new Web3("https://rpc.compverse.io");
                const lockupContract = new web3.eth.Contract(
                    fakeLockABI,
                    (this as any).lockupContractAddress
                );
                const userAddress = (this as any).fullAccount;
                const deposits = await this.getAllUserDeposits(
                    lockupContract,
                    userAddress
                );

                // Calculate total stake holdings in wei
                let totalWei = 0;
                let earnedRewards = 0;
                deposits.forEach((deposit) => {
                    // @ts-ignore
                    totalWei += Number(deposit);
                    // @ts-ignore
                    // earnedRewards += Number((this as any).earnedRewards);
                });
                (this as any).earnedRewards.forEach((reward) => {

                    earnedRewards += Number(reward);
                });
                // Convert totalWei to ether
                const totalEther = web3.utils.fromWei(totalWei.toString(), "ether");
                const totalRewardsEther = web3.utils.fromWei(earnedRewards.toString(), "ether");
                // Update the data property with the calculated value
                (this as any).totalStakeHoldings = totalEther;
                (this as any).totalearnedRewards = totalRewardsEther
            } catch (error) {
                console.error("Error calculating total stake holdings:", error);
            }
        },
        async getAllUserDeposits(lockupContract, userAddress) {
            const deposits: string[] = [];
            let depositIndex = 0;
            let depositExists = true;
            const web3 = new Web3("https://rpc.compverse.io");
            while (depositExists) {
                try {
                    const deposit = await lockupContract.methods.userDeposits(userAddress, depositIndex).call({ from: userAddress });

                    // Check if the deposit exists (valid data)
                    (this as any).earnedRewards.push(deposit.earnedRewards)
                    if (deposit) {
                        deposits.push(deposit.amount);

                        depositIndex++; // Move to the next deposit

                        if (!deposit.lockTimeCompleted) {
                            (this as any).totalCurrentRewards += parseFloat(web3.utils.fromWei(Number(deposit.earnedRewards).toString(), "ether"));

                        }
                    } else {


                        depositExists = false; // No more deposits for the user
                    }
                } catch (error) {

                    console.error("Error fetching deposit:", (this as any).error, deposits);
                    break; // Stop if there's an error
                }
                finally {
                    (this as any).fullscreenLoading = false;
                }
            }

            return deposits;
        },


        async getEFILTokenBalance() {



            try {
                // Initialize Web3
                if (window.ethereum) {
                    const web3 = new Web3("https://rpc.compverse.io");

                    // Replace the contract address and ABI with the actual FIL token contract information
                    const efilTokenContract = new web3.eth.Contract(
                        efilecoinABI, // Replace with the FIL token ABI
                        '0x5C4772038757Ae9603737f16BA2e0D2ff06e545d' // Replace with the FIL token contract address
                    );

                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    const sender = accounts[0];

                    const _18Number = new BigNumber('1000000000000000000'); // Use BigNumber for precision

                    // Call the balanceOf method of the FIL token contract
                    const balanceWei = await efilTokenContract.methods.balanceOf(sender).call();

                    // Convert the balance from Wei to FIL
                    const balanceFIL = web3.utils.fromWei(balanceWei.toString(), "ether"); // Convert to string to avoid precision issues

                    // Update the component's data with the balance
                    (this as any).efilTokenBalance = balanceFIL;
                } else {
                    console.error("MetaMask not detected. Please install MetaMask.");
                }
            }
            catch (error) {



                console.error("Network switch error:", error);




            }
        },

        async getWalletBalance() {
            if (typeof window.ethereum === "undefined") {
                console.error("MetaMask is not installed.");
                return;
            }
            const currentNetworkId = await window.ethereum.request({
                method: "eth_chainId",
            });
            const desiredNetworkId = "0x13a";

            try {

                // Connect to MetaMask wallet
                (this as any).metaConnected = true;
                if (desiredNetworkId == currentNetworkId) {

                    await window.ethereum.request({ method: "eth_requestAccounts" });
                    const provider = new ethers.providers.Web3Provider(window.ethereum);

                    // Get the current account address
                    const accounts = await provider.listAccounts();
                    const currentAccount = accounts[0];

                    // Fetch the wallet balance
                    const balance = await provider.getBalance(currentAccount);

                    // Convert balance to ETH
                    (this as any).walletBalance = ethers.utils.formatEther(balance);
                }

                else {
                    (this as any).walletBalance = 'Unable to obtain'
                }
            } catch (error) {
                console.error("Error fetching wallet balance:", (this as any).error);
            }
        },

        copyCPVAddress() {
            (this as any).$copyText((this as any).fullAccount).then(
                () => {
                    ElMessage.success((this as any).$t("common.message.copy-success"));
                },
                (e: any) => {
                    ElMessage.error(
                        `${(this as any).$t("common.message.some-error-happened")}: ${e}.`
                    );
                }
            );
        },
        copyFilAddress() {
            (this as any).$copyText((this as any).f4FullAccount).then(
                () => {
                    ElMessage.success((this as any).$t("common.message.copy-success"));
                },
                (e: any) => {
                    ElMessage.error(
                        `${(this as any).$t("common.message.some-error-happened")}: ${e}.`
                    );
                }
            );
        },
        async getAccountInfo() {
            const accounts = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (accounts.length > 0) {
                (this as any).fullAccount = accounts[0];
                (this as any).account = (this as any).fullAccount.slice(0, 6) + "..." + (this as any).fullAccount.slice(-6);
            }
        },
        toggleWallet(event) {
            (this as any).showDropdown = !(this as any).showDropdown;
            if ((this as any).showDropdown) {
                // Stop event propagation when the dropdown is opened
                event.stopPropagation();
            }
        },


    },
};
</script>

<style scoped>
.container {
    background: var(--ararat, linear-gradient(89deg, rgba(80, 202, 255, 0.37) 16.97%, rgba(216, 180, 243, 0.32) 83.72%));
}


.dropdown {
    columns: 2;
    /* Set the number of columns you want */
    list-style-type: none;
    padding: 0;
}

.dropdown div {
    margin: 10px 0;
}
</style>
