<template>
  <div class="modal-overlay">
    <div class="modal-content !bg-transparent  p-[20px]  1xs:p-[30px]  md:p-[50px] lg:pb-[8px]">
      <div class="modalContainer  p-[20px]  1xs:p-[30px]  md:p-[50px] ">
        <div class="welcome text-[22px]  1xs:text-[36px]">
          Welcome to GPU by Power Meta!
        </div>
        <div>
          <img src="@/assets/images/welcome.svg" alt="welcome" />
        </div>
        <div class="description my-[10px] text-[16px]  1xs:text-[20px]">
          Congratulations on creating an account! View your GPU CDID Token in your wallet and earn a 0.5 CPV reward with
          your first swap.
        </div>
        <div class="buttonContainer cursor-pointer" @click="goto('/')">
          <div class="confirm">Confirm</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "WelcomeView",
  data() {
    return {
      referral: false,
    };
  },
  mounted() {
    if (localStorage.getItem("_ApplyBonus")) {
      (this as any).referral = true;
      localStorage.removeItem("ApplyBonus");
    }
  },
  methods: {
    goto(link: string) {
      (this as any).$parent.closeWelcome();
      (this as any).$router.push(link);
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.modalContainer {
  display: flex;


  max-width: 1007px;

  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(50px);
}

.welcome {
  color: #FFF;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.description {
  color: #FFF;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);

}

.confirm {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
</style>