import en from './locales/en.json';


var lang = navigator.language || (navigator as any).userLanguage;
const localLang = localStorage.getItem("ararat_lang");

const DEFAULT_LOCALE = 'en';
const LOCAL_LANG = lang.replace('-', '');

export const supportedLanguages = {
  en: "En",
  // zhCN: "简体中文",
  // zhTW: "繁體中文",
};

export const languagesDict = {
  en: "EN",
  // zhCN: "CN",
  // zhTW: "TC"
}

export function currentLanguage() {
  let desc = supportedLanguages['en'];
  Object.keys(supportedLanguages).forEach((k: any) => {
    if (k == localLang) {
      desc = supportedLanguages[k as keyof object];
    }
  });
  return desc;
}

export function currentLanguageDesc() {
  let desc = supportedLanguages['en'];
  Object.keys(supportedLanguages).forEach((k: any) => {
    if (k == localLang) {
      desc = supportedLanguages[k as keyof object];
    }
  });
  return desc;
}

const i18nConfig = {

  locale: localLang || LOCAL_LANG, // set current locale
  fallbackLocale: DEFAULT_LOCALE,
  messages: {
    en: en,
    // enUK: en,
    enUS: en,

  }
}

export default i18nConfig;
