import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/HomeView.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/views/ContactUsView.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("@/views/AboutView.vue"),
    },
    {
      path: "/order",
      name: "order",
      component: () => import("@/views/OrderView.vue"),
    },
    {
      path: "/order/plan/:version/:id/:prodId",
      name: "orderPlan",
      component: () => import("@/views/OrderPlanView.vue"),
    },
    {
      path: "/order/withdraw-yield",
      name: "withdrawYield",
      component: () => import("@/views/WithdrawYieldView.vue"),
    },
    {
      path: "/swap",
      name: "swap",
      component: () => import("@/views/SwapView.vue"),
    },
    {
      path: "/myprofile",
      name: "myprofile",
      component: () => import("@/views/MyProfileView.vue"),
    },
    // {
    //   path: "/editprofile",
    //   name: "editprofile",
    //   component: () => import("@/views/EditProfileView.vue"),
    // },
    {
      path: "/invitation",
      name: "invitefriend",
      component: () => import("@/views/InvitationView.vue"),
    },
    {
      path: "/invite",
      name: "invite",
      component: () => import("@/views/InviteView.vue"),
    },
    {
      path: "/ambassador",
      name: "ambassador",
      component: () => import("@/views/AmbassadorView.vue"),
    },
    {
      path: "/ambassador-rewards",
      name: "ambassadorRewards",
      component: () => import("@/views/AmbassadorRewardsView.vue"),
    },
    {
      path: "/market",
      name: "market",
      component: () => import("@/views/MarketView.vue"),
    },
    {
      path: "/marketdetail/:productId",
      name: "marketdetail",
      component: () => import("@/views/MarketDetailView.vue"),
    },
    {
      path: "/wallet",
      name: "wallet",
      component: () => import("@/views/MyWalletView.vue"),
    },
    {
      path: "/wallet/account-safety",
      name: "walletAccountSafety",
      component: () => import("@/views/AccountSafetyView.vue"),
    },
    {
      path: "/foundrymember",
      name: "foundrymember",
      component: () => import("@/views/FoundryMemberView.vue"),
    },
    {
      path: "/node-info",
      name: "nodeInfo",
      component: () => import("@/views/NodeInfoView.vue"),
    },
    {
      path: '/result/:result',
      name: 'result',
      // params: true,
      component: () => import('../views/ResultView.vue')
    },
    {
      path: '/approval',
      name: 'approval',
      component: () => import('../views/ApprovalView.vue')
    },
    {
      path: '/security',
      name: 'security',
      component: () => import('../views/AccountSafetyView.vue')
    },
    {
      path: '/optional',
      name: 'optionalSettings',
      component: () => import('../views/NotificationsAndUpdates.vue')
    },
    {
      path: '/setup',
      name: '/setup',
      component: () => import('../views/Onboarding.vue')
    },
        {
      path: '/short-term-mining',
      name: 'stake',
      component: () => import('../views/StakeView.vue')
    },
        {
      path: '/power-up-mining',
      name: 'powerMining',
      component: () => import('../views/PowerUpMiningView.vue')
    },
            {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('../views/MaintenanceView.vue')
    },
                {
      path: '/ai-factory',
      name: 'aifactory',
      component: () => import('../views/AIFactoryView.vue')
    },
                    {
      path: '/ai-factory/:productId',
      name: 'aifactoryOrder',
      component: () => import('../views/AIFactoryOrderView.vue')
    },
                        {
      path: '/ai-dashboard',
      name: 'ai-dashboard',
      component: () => import('../views/AIFactoryDashboardView.vue')
    },
                            {
      path: '/ai-purchase-history',
      name: 'ai-history',
      component: () => import('../views/AIFactoryPurchaseHistoryView.vue')
    },
    {
      path: '/ai-purchase-success',
      name: 'ai-purchase',
      component: () => import('../views/AIPaymentSuccessful.vue')
    },
        {
      path: '/ai-dashboard/pool/:cardId',
      name: 'AIPoolRecordView',
      component: () => import('../views/AIPoolRecordView.vue')
    },
            {
      path: '/ai-dashboard/rewards',
      name: 'AIPoolRewardsView',
      component: () => import('../views/AIPoolRewardsView.vue')
    },
                {
      path: '/ai-dashboard/rewards/:cardId',
      name: 'AIRewardsSingleView',
      component: () => import('../views/AIRewardsSingleView.vue')
    },
      {
    path: '/:pathMatch(.*)*', // Catch all routes
    name: 'NotFound',
      component: () => import('../views/NotFoundView.vue')
  }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

export default router;
