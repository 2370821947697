<template>

  <div class="max-w-con w-full px-[10px] sm:px-[40px] md:mx-[50px] lg:pb-[160px] ">
    <div class="flex flex-col ">
      <div class="flex flex-row gap-[10px] lg:gap-[20px] mb-[50px] ">
        <div class=" headContainer ">
          <img src="@/assets/images/google-octagon.svg" alt="user icon" class="w-[60px] h-[60px]" />
        </div>
        <div class=" flex-col text-white">
          <div class="subHeader text-[18px] "> Account Set up:</div>
          <div class="header !text-[28px] sm:!text-[36px]"> Authentication</div>
        </div>
      </div>
      <div class="googleContainer  p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px]">
        <div>
          <div class="authentication text-[20px]  sm:text-[28px]">
            Authentication
          </div>
          <div class="subHeader  text-[12px] 1xs:text-[14px] md:text-[18px]">1. Download "Authenticator" app</div>
          <div
            class="description  text-[12px] 1xs:text-[14px] md:text-[18px] lg:mt-[30px] mt-[10px] mb-[10px] lg:mb-[20px]">
            For iOS users,
            download the "Authenticator" app through the App
            Store.</div>
          <div class="description  text-[12px] 1xs:text-[14px] md:text-[18px]">For Android users, download the
            "Authenticator" app through the Google Play Store.
          </div>
        </div>
        <div class="subHeader text-[12px] 1xs:text-[14px] md:text-[18px] ">
          Suggested Authenticator:
        </div>
        <div class="flex flex-row gap-[20px]">
          <div>
            <img src="@/assets/images/icon-auth.svg" alt="authenticator" class="w-[80px] h-[80px]" />
          </div>
          <div>
            <img src="@/assets/images/icon-microsoft.svg" alt="authenticator" class="w-[80px] h-[80px]" />
          </div>

        </div>
        <div>

          <div class="subHeader  text-[12px] 1xs:text-[14px] md:text-[18px]">
            2. Add the Private Key to the “Authenticator” App
          </div>
          <div class="description  text-[12px] 1xs:text-[14px] md:text-[18px] lg:mt-[30px] mt-[10px] ">
            Open the "Authenticator" app on
            your mobile device and click the
            "+" . Scan the QR Code or enter the Private Key below to enable authentication and click "Add"..</div>

        </div>
        <div class="hidden md:flex md:flex-row gap-[20px] justify-between w-full">
          <div class="flex flex-row ">
            <img v-if="!setGoogleAuth" :src="googleAuthSecret?.google_auth_secret_url" class="w-[152px] h-[152px]"
              alt="qr code" />
          </div>
          <div class="flex flex-col  ">
            <div class="subHeader  text-[12px] 1xs:text-[14px] md:text-[18px]">
              Private key:
            </div>
            <div class="description  text-[12px] 1xs:text-[14px] md:text-[18px] ">
              {{ googleAuthSecret?.google_auth_secret }}
            </div>
          </div>
          <div class=" justify-center items-center flex  copyText cursor-pointer">
            <div class="copyButton" @click="doCopy">
              {{ $t("common.copy") }}
            </div>
          </div>

        </div>
        <div class=" flex flex-col  md:hidden gap-[20px] text-white justify-center  w-full">
          <div class="   justify-center flex">
            <img v-if="!setGoogleAuth" :src="googleAuthSecret?.google_auth_secret_url" class="w-[152px] h-[152px]"
              alt="qr code" />
          </div>
          <div class=" justify-center items-center flex  copyText cursor-pointer">
            <div class="copyButton" @click="doCopy">
              {{ $t("common.copy") }}
            </div>
          </div>


          <div class="flex flex-col    justify-centerw-full ">
            <div class="subHeader text-[16px] 1xs:text-[14px] md:text-[18px]   justify-center flex">
              Private key:
            </div>
            <div class="description text-[12px] 1xs:text-[14px] md:text-[18px]   justify-center flex">
              {{ googleAuthSecret?.google_auth_secret }}
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full">

          <div class="subHeader text-[16px] 1xs:text-[14px] md:text-[18px] mb-[20px]">
            3. Enter Verification Code
          </div>
          <div class="flex ">

            <input type="text" v-model="googleVerifyCode" class="inputContainer bg-transparent w-full text-white"
              :maxlength="6" :placeholder="setGoogleAuth === false
              ? $t('common.tips.please-input')
              : `Completed`
              " />
          </div>
        </div>
        <div v-if="setGoogleAuth" class="buttonContainer cursor-pointer text-white w-full continueText"
          @click="continueOnboarding">
          {{ $t("common.continue") }}
        </div>

        <div v-else class="buttonContainer cursor-pointer text-white w-full  cursor-pointer  continueText"
          @click="bindGoogleAuth">
          {{ $t("common.continue") }}
        </div>
      </div>
    </div>
  </div>


</template>

<script lang="ts">
import request from "@/common/http";
import { getEncryptStr, toNFix } from "@/common/utils";
import { $context, $user } from "@/store";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import GoogleAuthModal from "./GoogleAuthModal.vue";

export default {
  name: "googleAuth",
  components: {
    GoogleAuthModal,
  },
  data() {
    return {
      showGoogleAuthModal: false,
      setGoogleAuth: false,
      inputEmail: ref(""),
      $context,
      $user,
      googleAuthSecret: null,
      googleVerifyCode: "",
      googleCodeKey: "",
    };
  },
  mounted() {
    localStorage.setItem("onboardingPhase", "3");
    (this as any).googlePhaseCheck();
    if ($user.isLogined && !(this as any).setGoogleAuth) {
      (this as any).openCaptchaDialog();
    }
  },
  watch: {
    remainSecond(newValue: number, oldValue: number) {
      if (newValue == 0) {
        return;
      }
      (this as any).remainSecond > 0 &&
        setTimeout(() => ((this as any).remainSecond -= 1), 1000);
    },
  },
  methods: {
    async googlePhaseCheck() {
      const onboardingCheck: any = await request.get("/wallet/info");
      if (onboardingCheck.google_code_isset === true) {
        (this as any).setGoogleAuth = true;
      }
    },
    closeGoogleAuthModal() {
      (this as any).showGoogleAuthModal = false;
      if ((this as any).setGoogleAuth) {
        (this as any).continueOnboarding();
      }
    },
    continueOnboarding() {
      (this as any).$emit("continue-onboarding-to-backupKey");
    },
    toNFix,

    async openCaptchaDialog() {
      const onboardingCheck: any = await request.get("/wallet/info");
      if (
        (this as any).setGoogleAuth === false ||
        !onboardingCheck.google_code_isset
      ) {
        (this as any).googleAuthSecret = await request.post(
          "/google_code/get_google_key",
          {
            private_key: (this as any).googleCodeKey,
          }
        );
      }

      if ((this as any).googleAuthSecret) {
        (this as any).googleVerifyCode = "";
      }
    },
    async bindGoogleAuth() {
      try {
        if ((this as any).setGoogleAuth) {
          return;
        }
        if (!(this as any).googleVerifyCode) {
          ElMessage.error(
            (this as any).$t("wallet.please-enter-google-verify-code")
          );
          return;
        }

        const result: any = await request.post(
          "/google_code/google_auth_bind",
          {
            google_auth_secret: (this as any).googleAuthSecret
              .google_auth_secret,
            google_code: (this as any).googleVerifyCode,
            private_key: (this as any).googleCodeKey,
          }
        );
        ElMessage.success((this as any).$t("wallet.bind-successfully"));
        (this as any).setGoogleAuth = true;
        // (this as any).showGoogleAuthModal = true;
        (this as any).continueOnboarding();
      } catch {

        // (this as any).showGoogleAuthModal = true;
        ElMessage.warning('verification code is incorrect');
      }
    },
    openVerifyKeyDialog() {
      (this as any).googleCodeKey = "";
    },
    toCaptchaDialog() {
      if (!(this as any).googleCodeKey) {
        ElMessage.error(
          (this as any).$t("wallet.please-enter-google-verify-code")
        );
        return;
      }

      (this as any).openCaptchaDialog();
    },

    doCopy() {
      (this as any)
        .$copyText((this as any).googleAuthSecret?.google_auth_secret)
        .then(
          () => {
            ElMessage.success((this as any).$t("common.message.copy-success"));
          },
          (e: any) => {
            ElMessage.error(
              `${(this as any).$t("common.message.some-error-happened")}: ${e}.`
            );
          }
        );
    },
  },
};
</script>

<style scoped>
.googleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
}

.authentication {
  color: #FFF;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.header {
  color: #FFF;
  font-family: "Work Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.headContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.copyText {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.subHeaderLight {
  color: #FFF;
  opacity: 0.5;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.copyButton {
  display: flex;
  padding: 7px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.description {
  color: #898EFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.subHeader {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;


}

.inputContainer {
  display: flex;
  height: 70px;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.continueText {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
</style>
