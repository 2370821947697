<template>
  <div class="footerBox ">
    <div class="footerTop max-w-con">
      <el-row class="footerTopFlex" justify="space-between">
        <el-col :sm="24" :md="7">
          <div class="footerTopBox align-left   ">
            <img src="@/assets/images/PMlogo.svg" class="footerLogo " />
            <span class="companyInfo">With Power Meta, anyone can power AI Cloud Computing projects and
              start earning today.</span>
          </div>
        </el-col>
        <el-col :sm="24" :md="7">
          <div class="footerTopBox ">
            <p class="footerTitle ">Contacts</p>
            <div class="contactsBox">
              <span>17322 Murphy Ave Suite 3, Irvine, CA 92614</span>
              <span class="blueTextColor">+1 (949) 849 7319</span>
              <span>info@powermeta.cloud</span>
            </div>
            <a class="footerTitle callWithUs" href="https://calendly.com/power-meta" target="_blank">Schedule a call
              with us</a>
          </div>
        </el-col>
        <el-col :sm="24" :md="7">
          <div class="footerTopBox footerBottomBox ">
            <p class="footerTitle ">Subscribe for Updates</p>
            <div class="emailBox">
              <input type="text" v-model="email" class="w-full" placeholder="Enter email" />
              <button class="blueBtnColor" @click="validateEmail">Submit</button>
            </div>
            <div
              class="flex flex-wrap 2xl:flex-nowrap items-center justify-between xl:justify-start 2xl:justify-between lg:ml-[-25px] lg:w-[80%] 2xl:w-[80%]">
              <img class="cursor-pointer w-[20px] " src="@/assets/images/icon-telegram-footer.svg" alt=""
                onclick="window.open('https://t.me/powermetacorp')" />
              <img class="cursor-pointer w-[15px]  " src="@/assets/images/icon-twitter-footer.svg" alt=""
                onclick="window.open('https://x.com/PowerMetaAI')" />
              <img class="cursor-pointer w-[20px] " src="@/assets/images/icon-discord-footer.svg" alt=""
                onclick="window.open('https://discord.gg/M2VrYzfmzK')" />
              <img class="cursor-pointer w-[20px]  " src="@/assets/images/icon-linkedin-footer.svg" alt=""
                onclick="window.open('https://www.linkedin.com/company/power-meta-corp/')" />
              <img class="cursor-pointer w-[20px]  " src="@/assets/images/icon-youtube-footer.svg" alt=""
                onclick="window.open('https://www.youtube.com/@powermetaAI')" />
              <img class="cursor-pointer w-[20px] " src="@/assets/images/icon-instagram-footer.svg" alt=""
                onclick="window.open('https://www.instagram.com/powerm.ai/')" />
              <img class="cursor-pointer w-[20px]  " src="@/assets/images/icon-facebook-footer.svg" alt=""
                onclick="window.open('https://www.facebook.com/profile.php?id=61561622977276/')" />
            </div>

          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    gradientStyle() {
      return {
        background: 'linear-gradient(to right, hsla(207, 44%, 42%, 1), hsla(214, 46%, 64%, 1), hsla(205, 40%, 37%, 1), hsla(26, 7%, 19%, 1))'
      };
    }
  },
  methods: {
    validateEmail() {
      if (
        !(this as any).email.includes("@") &&
        !(this as any).email.includes(".")
      ) {
        ElMessage.error((this as any).$t("Please enter a valid email address"));
      } else {
        (this as any).addContact();
      }
    },

    addContact() {
      fetch("https://api.apispreadsheets.com/data/Nitu5e1EsmRgMFWQ/", {
        method: "POST",
        body: JSON.stringify({ data: { Email: (this as any).email } }),
      }).then((res) => {
        if (res.status === 201) {
          // SUCCESS
          ElMessage.success("Added to newsletter");
        } else {
          // ERROR
          ElMessage.error((this as any).$t("unable to add"));
        }
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');



.blueTextColor {
  background: var(--fade, linear-gradient(90deg, #00A0E9 0%, #4610E9 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Montserrat';
  font-size: 21.943px;
  font-style: normal;
  font-weight: 400;
  line-height: 190%;
}

.blueBtnColor {
  border-radius: 10.971px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
  color: var(--White-Color, #FFF);
  font-family: "DM Sans";
  font-size: 19.2px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

}

.footerBox {
  width: 100%;
  background-color: #0f1123;
  padding: 40px 0 50px 0;

}

.footerTop {
  width: 100%;
  padding: 0 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin: auto;
}

.footerTopBox {


  text-align: center;

  text-align: left;
  margin: 0 auto;

}

.footerLogo {
  height: 30px;
  width: 150px;
}

.companyInfo {
  display: block;
  font-size: 12px;
  font-family: 'Montserrat';
  line-height: 30px;
  color: #fff;
  margin-top: 24px;
}

.footerBottomBox img {
  margin: 35px 0 0 20px;
}

.contactsBox {
  margin-top: 24px;
}

.contactsBox span {
  color: #fff;
  font-size: 12px;
  line-height: 30px;
  display: block;
  line-height: 30px;
  font-family: 'Montserrat';
}

.contactsBox i {
  font-style: normal;
  font-weight: 600;
}

.footerTopFlex {
  width: 100%;
}

.footerTitle {
  display: flex;
  align-items: center;


  font-size: 14px;
  color: #fff;
  line-height: 34px;
  font-family: 'Michroma';
}

.emailBox {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 24px;
  background-color: rgb(29, 31, 49);
  border-radius: 12px;
}

.emailBox input {
  height: 30px;
  background-color: rgb(29, 31, 49);
  border: none;
  color: #fff;
  outline: none;
}

.emailBox input::placeholder {
  color: var(--Paragraph-Color, #848296);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 190%;
  padding-left: 30.17px;
  /* 33.874px */
}

.emailBox button {
  width: 105px;
  height: 30px;
  color: #fff;
  font-size: 12px;
  border: none;
  border-radius: 8px;
}

.callWithUs {
  display: inline-block;
  margin: 20px 0;
  border-bottom: 1px solid white;
  font-size: 12px;
}


@media only screen and (max-width: 767px) {
  .footerTopBox {
    width: auto;

  }

  img {
    margin: 0;
  }

  .companyInfo {
    margin-top: 10px;
  }

  .footerTitle {
    margin-top: 20px;
  }

  .contactsBox {
    margin-top: 0;
  }

  .footerBottomBox img {
    margin: 25px 20px 0 0;
  }
}
</style>
