<template>

  <div v-if="step === 1" class="max-w-con  px-[10px] sm:px-[40px] md:px-[50px]  pb-12 lg:pb-[160px] ">
    <div class="flex flex-col">
      <div class="flex flex-row gap-[20px]">
        <div class=" headContainer ">
          <img src="@/assets/images/key-octagon.svg" alt="user icon" class="w-[60px] h-[60px]" />
        </div>
        <div class=" flex-col text-white">
          <div class="subHeader"> Account Set up:</div>
          <div class="header !text-[26px] sm:!text-[36px]">Account Private Key</div>
        </div>
      </div>
      <div class="subHeaderLight mt-[20px] mb-[50px] text-[12px] 1xs:text-[14px] md:text-[18px] ">Back up the private
        key of your GPU wallet account to ensure
        access to your account.</div>

    </div>

    <div class="privateContainer p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px] ">
      <div class="backupKey text-[20px]  sm:text-[28px]">
        Back up Account Private Key
      </div>
      <div class="description text-[12px] 1xs:text-[14px] md:text-[18px]">
        Back up and save the private key of your account to add another layer of security in recovering your account. Do
        not disclose the private key to anyone.
      </div>
      <div class="description text-[12px] 1xs:text-[14px] md:text-[18px]">
        WARNING: Do not disclose this key. Anyone with your private key can change the email account to steal any assets
        held in your account.
      </div>
      <div class="keyContainer flex flex-col lg:flex-row justify-between ">
        <div class="flex  flex-col lg:flex-row  gap-[20px]">
          <div class=" m-auto lg:m-0">
            <img src="@/assets/images/icon-key.svg" alt="key image" class="w-[119px] h-[119px]" />
          </div>
          <div class="flex flex-col  items-center">
            <div class="descriptionBold text-[18px] ">
              Private key:
            </div>
            <div class="keyText text-[9px]  2xs:text-[12px]  sm:text-[18px]">
              {{ privateKeyPart1 }}
            </div>
            <div class="keyText text-[9px]  2xs:text-[12px]   sm:text-[18px]"> {{ privateKeyPart2 }}
            </div>
            <div class="keyText  text-[9px] 2xs:text-[12px]   sm:text-[18px]">
              {{ privateKeyPart3 }}
            </div>
          </div>
        </div>
        <div class="buttonContainer cursor-pointer" @click="copyBackup">
          <div class="description text-[12px] 1xs:text-[14px] md:text-[18px]">
            Copy
          </div>
        </div>
      </div>
      <div class="note text-[12px] md:text-[16px]">
        Important: Your private key has been encrypted for your safety. Keep it secure, as it controls your wallet's
        asset security. GPU by Power Meta will never ask for your private key.
      </div>
      <div class="continueButton cursor-pointer" @click="continueForward">
        <div class=continueButtonText>

          {{ $t("common.continue") }}
        </div>
      </div>
    </div>
  </div>

  <div v-else class="max-w-con  px-[10px] sm:px-[40px] md:px-[50px]  pb-12 lg:pb-[160px]">
    <div class="flex flex-col">
      <div class="flex flex-row gap-[20px]">
        <div class=" headContainer ">
          <img src="@/assets/images/key-octagon.svg" alt="user icon" class="w-[60px] h-[60px]" />
        </div>
        <div class=" flex-col text-white">
          <div class="subHeader"> Account Set up:</div>
          <div class="header !text-[28px] sm:!text-[36px]">Account Private Key</div>
        </div>
      </div>
      <div class="subHeaderLight mt-[20px] mb-[50px]">Set up a transaction passcode to authorize and validate any
        transactions made through your account.</div>

    </div>
    <div class="privateContainer p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px] ">
      <div class="backupKey text-[20px]  sm:text-[28px]">
        Verify Account Private Key
      </div>
      <div class="description text-[12px] 1xs:text-[14px] md:text-[18px]">
        WARNING: Do not disclose this key. Anyone with your private key can change the email account to steal any assets
        held in your account.
      </div>
      <div class="w-full">

        <div class="descriptionBold text-[18px] mb-[5px] ">
          Private key:
        </div>
        <div class="inputContainer h-[120px] sm:h-[70px] !bg-transparent">

          <textarea type="textarea" v-model="confirmKey" class="!bg-transparent text-white  w-full my-auto " :rows="3"
            resize="none" :class="[setBackup === false ? '' : 'pointer-events-none']" @input="validateNumber"
            :placeholder="setBackup === false ? $t('common.tips.please-input') : `Completed`
    " />
        </div>
      </div>
      <div class="note text-[12px] md:text-[16px]">
        Note: Your private key has been encrypted, please keep it safe as there is no way to recover your account if
        lost.
      </div>
      <div v-if="!setBackup" class="w-full">

        <div class="continueButton cursor-pointer w-full " @click="completeBackup">
          <div class=continueButtonText>
            {{ $t("common.continue") }}
          </div>
        </div>
      </div>
      <div v-else @click="continueOnboardingStep" class="w-full">
        <div class="continueButton cursor-pointer w-full">
          <div class=continueButtonText>
            {{ $t("common.continue") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import request from "@/common/http";
import { $user } from "@/store";
import { ElMessage } from "element-plus";
import BackupPrivateKeyModal from "./BackupPrivateKeyModal.vue";

export default {
  name: "BackupPrivateKeyView",
  components: {
    BackupPrivateKeyModal,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    skip: {
      type: Boolean,
    },
  },
  data() {
    return {
      showBackupModal: false,
      setBackup: false,
      backupPrivateKeyDialogVisible: true,
      step: 1,
      privateKey: "",
      confirmKey: "",
      $user,
      splitIndex: "",
      privateKeyPart1: "",
      privateKeyPart2: "",
    };
  },
  mounted() {
    localStorage.setItem("onboardingPhase", "4");
    if ((this as any).skip) {
      (this as any).step = 2;
    }
    if ((this as any).step === 1 && !(this as any).setBackup) {
      (this as any).getPrivateKey();
    }
    (this as any).backupPhaseCheck();
  },
  watch: {
    showDialog(newValue: Boolean) {
      if (newValue) {
        (this as any).backupPrivateKeyDialogVisible = true;
      }
    },
  },
  methods: {
    async backupPhaseCheck() {
      const onboardingCheck: any = await request.get("/wallet/info");
      if (onboardingCheck.private_key_backup === true) {
        (this as any).setBackup = true;
      }
    },
    closeBackupModal() {
      (this as any).showBackupModal = false;
      if ((this as any).setBackup) {
        (this as any).continueOnboardingStep();
      }
    },
    continueOnboardingStep() {
      (this as any).$emit("continue-onboarding-to-confirmation");
    },
    async getPrivateKey() {
      const result: any = await request.get("/private/get_key");
      (this as any).privateKey = result.private_key;
      const totalLength = (this as any).privateKey.length;

      const splitIndex1 = Math.floor(totalLength / 3);
      const splitIndex2 = Math.floor(2 * (totalLength / 3));

      (this as any).privateKeyPart1 = (this as any).privateKey.substring(
        0,
        splitIndex1
      );
      (this as any).privateKeyPart2 = (this as any).privateKey.substring(
        splitIndex1,
        splitIndex2
      );
      (this as any).privateKeyPart3 = (this as any).privateKey.substring(
        splitIndex2
      );
    },

    copyBackup() {
      (this as any).$copyText((this as any).privateKey).then(
        () => {
          ElMessage.success((this as any).$t("common.message.copy-success"));
        },
        (e: any) => {
          ElMessage.error(
            `${(this as any).$t("common.message.some-error-happened")}: ${e}.`
          );
        }
      );
    },
    goBack() {
      (this as any).step = 1;
    },
    continueForward() {
      (this as any).step = 2;
      (this as any).$emit("backupStep");
      // (this as any).$emit('backupStep');
    },
    async completeBackup() {
      if (!(this as any).confirmKey) {
        ElMessage.error(
          (this as any).$t("profile.backup-key.enter-private-key")
        );
        return;
      }
      const trimConfirmKey = (this as any).confirmKey.trim();
      const keyconfirm = await request.postFormData("/private/verify_key", {
        private_key: trimConfirmKey,
      });
      if (keyconfirm !== null) {
        ElMessage.success("Verify success.");
        (this as any).setBackup = true;
        const userInfo: any = await request.get("/user/info");
        $user.updateUserInfo(userInfo);
        (this as any).continueOnboardingStep();
        return;
      }
      else {
        ElMessage.error(
          'Invalid private key. Refresh page to go back'
        );
      }

    },
  },
};
</script>
<style scoped>
.buttonContainer {
  display: flex;
  padding: 7px 30px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.keyContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.headContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.subHeaderLight {
  color: #FFF;
  opacity: 0.5;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.note {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.inputContainer {
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.subHeader {
  color: #FFF;

  opacity: 0.8;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

}



.header {
  color: #FFF;
  font-family: "Work Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}


.backupKey {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.privateContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
}

.description {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

}

.continueButton {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.continueButtonText {
  color: var(--ffffff, #FFF);


  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

}

.descriptionBold {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

input {
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

textarea {
  resize: none;
  outline: none;
}

.keyText {
  color: #898EFF;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
