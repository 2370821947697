import { isMainnet } from "./utils";

export const chainId = isMainnet() ? "0x1a7b" : "0xd94";

export const CLIENT_ID = "BO6L6T55wOW6EYGtmc_VOYLdjRZjE0I3Lv_3NiWZ-RIyxb9FUZAddEWTvIHPTh5rSgUAJC_3VLNN_efQlBp1gaA";

export const K_TOKEN = "ararat_token";

export const RSA_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8Au+CTBMRZrunVQmjI9q+BgASuE3c5kt17F//Dvcsk+5A7gK8nzUy9pT9SjVxChuHVnYupq4EAi0PDt8kViT25aXinxtXP0FJ9WCg+J62PEDbKf11mHo2/Pd817bdXvBQQpdL4cgcBBWM7M2COQ+gP++XchPwVXfXr9QRmUQw3aFHmtUQnLgZGux+ttXGLIOUX6GGMxwggNJ8LuOarGzCORs8E+VQEFfAML9xm/cyLnNm6a8Mjx6Eo+T3pXi/evFN9Awh29S26brucgxk1HcespqKVoeZbVCctL9pyPVO2djDwvF+O5tP2sF+0bV9sq8N1A6X+LKe6zRh/VLMg+yHwIDAQAB
-----END PUBLIC KEY-----`


export const NEED_LOGIN_PATHS = "swap,order,wallet"

export const RECAPTCHA_KEY = isMainnet() ? "6Lf7FjEmAAAAANfbCZljjFPqowuK-e_wgTukuChX" : "6LdUGiwmAAAAAA-zmh5Qx-zbF95e3IumNV_lcKFJ"



export const CPV_KEY = 'dd91e16f150c9320d6800e6bf57a2a41f26f698149898e9f5a499633aead08ce';