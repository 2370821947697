<template>

  <div class="bg-[#05040E] bg-components">
    <div class="w-full max-w-con  mx-auto px-[10px] sm:px-[40px] md:px-[50px]  pb-12 lg:pb-[160px] ">


      <div class="flex flex-col">
        <div class="flex updateHeader text-[24px] sm:text-[36px] text-white  mt-[20px] 1xs:mt-[30px] md:mt-[50px]  ">

          Notifications & Updates (Optional)
        </div>

        <div
          class="flex updateSubHeader text-[12px] 1xs:text-[14px] md:text-[18px] text-white pt-[20px] pb-[20px] 1xs:pb-[30px] md:pb-[50px]">
          Enter your email and phone number for exclusive notifications and updates on airdrops and product updates. For
          your personalized code and support,
          Email: contactus@powermeta.cloud OR Telegram: Power_Meta
        </div>
      </div>
      <div class="optionsContainer p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px] ">

        <div class="">
          <div class="text-white emailNotifications text-[20px]  sm:text-[28px]">

            {{ $t("optional.email-notifications") }}
          </div>
          <div class="updateSubHeader text-[12px] 1xs:text-[14px] md:text-[18px] pt-[20px]">
            Register your email to receive Transaction Confirmation Emails and our Newsletter.
          </div>
        </div>

        <div :class="[
              emailUser === false ? '' : 'pointer-events-none  ',
            ]" class="inputContainer w-full flex flex-row  relative ">
          <div class="flex w-full  ">

            <input type="email" v-model="email" class="bg-transparent p-[20px] w-full  " size="large"
              :placeholder="$t('login.via-email.your-email')" />
          </div>
          <div class="flex hidden sm:block">

            <button @click="saveEmailInfo" :class="[
              emailUser === false ? 'verifyContainer' : 'pointer-events-none  verifyContainerDisabled ',
            ]"
              class=" absolute   right-[20px] top-1/2 transform -translate-y-1/2 cursor-pointer text-white  w-[104px] h-[44px] text-[16px]">
              <div class="w-full">
                {{ $t("common.set") }}
              </div>
            </button>
          </div>
        </div>
        <div class="flex sm:hidden w-full">


          <button @click="saveEmailInfo" :class="[
              emailUser === false ? 'verifyContainer ' : 'pointer-events-none verifyContainerDisabled  ',
            ]" class=" relative   verifyContainer cursor-pointer text-white w-[104px] h-[44px] text-[16px] ">
            <div class="w-full">
              {{ $t("common.set") }}
            </div>
          </button>
        </div>


        <div class="transactionConfirmation text-white" @click="transactionConfirmationOptInSend">
          <label class="flex items-center text-[12px] text-white sm:text-[16px]">
            <input type="checkbox" class="mr-2 !w-[30px] !h-[20px]  mt-[-27px]"
              v-model="transactionConfirmationOptIn" />
            Transaction Confirmation Emails Opt-in: Receive “Transaction Confirmation Emails” any time a I transaction
            is made on the blockchain. You will receive an email once a transaction generates a transaction hash ID and
            is posted on the blockchain.
          </label>
        </div>

        <div class="transactionConfirmation" @click="araratNewsletterOptInSend">
          <label class="flex items-center text-[12px] text-white sm:text-[16px] ">
            <input type="checkbox" class="mr-2 w-[20px] h-[20px] mt-[-27px]" v-model="araratNewsletterOptIn" />
            Newsletter Email Opt-in: Receive our Newsletter to stay up to date with any and all new email updates and
            access to exclusive subscriber content.
          </label>
        </div>
        <div>

          <div class="emailNotifications text-[20px]  sm:text-[28px]">
            GPU Airdrop: Telegram Verification (optional)
          </div>
          <div class="updateSubHeader text-[12px] 1xs:text-[14px] md:text-[18px] mt-[10px]">
            Add your Telegram in order to verify your account for any exclusive Ararat Airdrops.
          </div>
        </div>

        <div class="inputContainer w-full flex flex-row relative border">
          <div class="flex w-full">
            <div class="flex flex-row w-full rounded tgInput">
              <div
                class="flex h-full text-[#909399]  items-center p-[20px] gap-2 w-24 h-14 md:h-20 font-bold text-[12px] md:text-xl w-full !text-[12px] md:!text-[20px]">

                t.me/
              </div>

              <input placeholder="Enter Telegram Username" v-model="telegram"
                class="bg-transparent  w-full h-14 md:h-20 font-bold text-[12px] md:text-xl border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="flex hidden sm:block">
            <button @click="saveTelegramInfo"
              class="absolute right-[20px] verifyContainer top-1/2 transform -translate-y-1/2 cursor-pointer text-white w-[104px] h-[44px] text-[16px]">
              <div class="w-full verifyText">
                {{ $t("common.set") }}
              </div>
            </button>
          </div>
        </div>
        <div class="flex  sm:hidden w-full">
          <button @click="saveTelegramInfo"
            class=" relative   verifyContainer cursor-pointer text-white w-[104px] h-[44px] text-[16px]">
            <div class="w-full verifyText">
              {{ $t("common.set") }}
            </div>
          </button>
        </div>


        <div class="flex flex-row updateSubHeader  justify-center items-center text-[12px] md:text-[18px]">
          <img class="w-[24px] h-[24px] mr-[10px]" src="@/assets/images/icon-warning.svg" />
          Telegram Verification is required to participate in any Airdrops.
        </div>
        <div class="buttonContainer cursor-pointer text-white w-full" @click..prevent="completeAllOnboarding">
          <div class="continue">
            {{ $t("common.continue") }}

          </div>
        </div>
      </div>
    </div>



    <div class=" w-11/12 m-auto sm:max-w-con">
      <div v-if="showEmailModal">
        <EmailModal :result="setEmail" :transactionOpt="transactionConfirmationOptIn"
          :newsletterOpt="araratNewsletterOptIn" />
      </div>
      <div v-if="showTelegramModal">
        <TelegramModal :result="setTelegram" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import request from "@/common/http";
import { getEncryptStr, toNFix } from "@/common/utils";
import { $context, $user } from "@/store";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import TransactionPasswordCheck from "../components/TransactionPasswordCheck.vue";
import EmailModal from "../components/EmailNotificationsModal.vue";
import TelegramModal from "../components/TelegramModal.vue";
export default {
  name: "NotificationsAndUpdates",
  components: { EmailModal, TelegramModal },
  data() {
    return {
      setTelegram: false,
      showTelegramModal: false,
      setEmail: false,
      showEmailModal: false,
      telegramAdjusted: "",
      optional: false,
      araratNewsletterOptIn: true,
      transactionConfirmationOptIn: true,
      emailUser: false,
      data: "",
      $user,
      nickName: "",
      imageUrl: "",
      imagePath: "",
      email: "",
      telegram: "",
      twitter: "",
      discord: "",
      instagram: "",
      fileName: "",
      cropDialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        size: 1, // 裁剪框的大小信息
        canMove: true,
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 400, // 默认生成截图框宽度
        // autoCropHeight: 400, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "auto 100%",
      },
      loading: false,
    };
  },
  mounted() {
    // if (localStorage.getItem("newWalletUser")) {
    if (localStorage.getItem("_optional")) {
      localStorage.removeItem("onboardingPhase");
      localStorage.setItem("onboardingPhase", "1");
      (this as any).optional = true;
    }

    // else {
    //   (this as any).$router.push("/");
    // }
    // }
    if ($user.isLogined) {
      (this as any).getLatestInfo();
      if (!$user.loginWithWallet) {
        (this as any).emailUser = true;
      }
    }
    // else {
    //   ElMessage.warning((this as any).$t("common.please-login"));
    //   (this as any).$router.push("/login");
    // }
  },
  methods: {
    continueOnboarding() {
      (this as any).$emit("continue-onboarding");
    },

    closeReferralModal() {
      (this as any).showEmailModal = false;
      (this as any).showTelegramModal = false;
    },
    redirectHome() {
      localStorage.removeItem("_optional");
      localStorage.setItem("_new", "1");
      (this as any).$router.go(0);
      (this as any).$router.push("/");
    },
    completeAllOnboarding() {
      if ((this as any).araratNewsletterOptIn && (this as any).email == "") {
        return ElMessage.error("Add a email to get the newsletter");
      } else if (
        (this as any).transactionConfirmationOptIn &&
        (this as any).email == ""
      ) {
        return ElMessage.error("Add a Email to opt-in, or uncheck to opt-out");
      } else if ((this as any).araratNewsletterOptIn) {
        fetch("https://api.apispreadsheets.com/data/Nitu5e1EsmRgMFWQ/", {
          method: "POST",
          body: JSON.stringify({ data: { Email: (this as any).email } }),
        }).then((res) => {
          if (res.status === 201) {
            // SUCCESS
            // ElMessage.success("Email added to newsletter");
          } else {
            // ERROR
            // ElMessage.error("unable to add");
          }
        });
      } else {
        (this as any).email = (this as any).email;
        fetch("https://api.apispreadsheets.com/data/Nitu5e1EsmRgMFWQ/", {
          method: "POST",
          body: JSON.stringify({ data: { Email: (this as any).email } }),
        }).then((res) => {
          if (res.status === 201) {
            // SUCCESS
            // ElMessage.warning("Email will not be added to newsletter");
          } else {
            // ERROR
            ElMessage.error((this as any).$t("error"));
          }
        });
      }

      if ((this as any).transactionConfirmationOptIn) {
        (this as any).sendTransactions();
      } else {
        // ElMessage.warning(
        //   "Email will not be getting transaction Confirmations"
        // );
      }
      if (!$user.loginWithWallet) {
        (this as any).step = 2;
        localStorage.removeItem("_optional");
        (this as any).continueOnboarding();
      } else {
        (this as any).redirectHome();
      }
      // (this as any).$emit("complete-optional-onboarding");
    },

    async sendTransactions() {
      try {
        let data = {};
        if ($user.loginWithWallet) {
          data = {
            email: (this as any).email,
          };
        } else {
          data = {
            email: $user.email,
          };
        }
        data = await request.postFormData("/user/set/email", data);

        // ElMessage.success("Email Will get Transaction Confirmations");

        (this as any).getLatestInfo();
      } catch (err: any) {
        ElMessage.error(err);
      }
    },

    araratNewsletterOptInSend() {
      (this as any).araratNewsletterOptIn = !(this as any)
        .araratNewsletterOptIn;
    },

    transactionConfirmationOptInSend() {
      (this as any).transactionConfirmationOptIn = !(this as any)
        .transactionConfirmationOptIn;
    },

    async getLatestInfo() {
      const _this = this as any;
      await _this.$root.getUserInfo();
      _this.nickName = $user.nickname;
      _this.imageUrl = $user.avatarPath;
      _this.imagePath = $user.avatar;
      _this.email = $user.email;
      _this.telegram = $user.tg;
      _this.twitter = $user.twitter;
      _this.discord = $user.discord;
      _this.instagram = $user.ins;
      _this.telegramAdjusted = $user.tg.split("/")[3];
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async saveTelegramInfo() {
      try {
        if ((this as any).telegram == "") {
          // (this as any).showTelegramModal = true;
          return ElMessage.error("Invalid Telegram");
          (this as any).setTelegram = false;
        }
        let data = {};
        if ($user.loginWithWallet) {
          data = {
            tg: (this as any).telegram,
          };
        } else {
          data = {
            tg: (this as any).telegram,
          };
        }
        data = await request.postFormData("/user/set/tg", data);
        (this as any).setTelegram = true;
        ElMessage.success((this as any).$t('Telegram Saved'));
        // (this as any).showTelegramModal = true;
        (this as any).getLatestInfo();
      } catch (err: any) {
        (this as any).setTelegram = false;
        return ElMessage.error("Telegram Error");
        // (this as any).showTelegramModal = true;
      }
    },

    async saveEmailInfo() {
      try {
        if ((this as any).email == "") {
          // (this as any).showEmailModal = true;
          (this as any).setEmail = false;
          return ElMessage.error("Invalid Email");
        }
        let data = {};
        if ($user.loginWithWallet) {
          data = {
            email: (this as any).email,
          };
        } else {
          data = {
            email: $user.email,
          };
        }
        data = await request.postFormData("/user/set/email", data);

        // ElMessage.success("Email Updated");
        // (this as any).showEmailModal = true;

        (this as any).setEmail = true;
        ElMessage.success((this as any).$t('Email Saved'));
        (this as any).getLatestInfo();
      } catch (err: any) {
        if (
          err ===
          "This email address has been bound, please use another email address"
        ) {
        } else {
          // (this as any).showEmailModal = true;
          (this as any).setEmail = false;
          ElMessage.error("Invalid Email");
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-components {
  background-image: url('@/assets/images/blur.svg');
  background-size: contain;
  background-repeat: no-repeat;

}

.continue {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.inputContainer input {
  color: white;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.inputContainer input::placeholder {
  color: grey;
}

.inputContainer {
  border-radius: 18px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.emailNotifications {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.verifyContainer {

  justify-items: center;
  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.verifyText {
  color: var(--ffffff, #FFF);


  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

}

.verifyContainerDisabled {

  text-align: center;

  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: gray;
}

.updateSubHeader {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.optionsContainer {
  display: flex;

  max-width: 1280px;
  flex-direction: column;
  align-items: flex-start;

  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
}

.updateHeader {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.colorText {
  color: #999999;
}

.borderColor {
  border-color: #eeeeee;
}

.cropper-content .cropper {
  width: 500px;
  height: 500px;
}

.modal-box .vue-cropper {
  background-image: none;
}

.modal-box .cropper-modal {
  background: none;
}

.registerText {
  color: #35312e;
  font-style: normal;
  font-weight: 400;

  /* 153.846% */
}

.transactionConfirmation {
  color: var(--black, #050505);
  font-family: 'Work Sans';

  font-style: normal;
  font-weight: 400;

}

.telegramVerificationText {
  background: var(--ararat-plue,
      linear-gradient(85deg, #50caff 0%, #d8b4f3 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-style: normal;
  font-weight: 700;


}

.setupHeader {
  color: #35312e;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
}
</style>
