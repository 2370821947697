<template>
  <div v-if="walletAccount"
    class="referralContainer gap-[20px]  1xs:gap-[30px] md:gap-[50px] text-white max-w-[960px]  m-auto p-[8px] lg:p-[0px] lg:pb-[8px] ">
    <div class="flex-col  max-w-[600px]">

      <div class="">
        <div class="referralHeader text-[24px] 3xs:text-[26px] 2xs:text-[30px]  1xs:text-[35px] lg:text-[42.167px] ">
          Have a Referral Code?
        </div>
        <div class="referralSubHeader  text-[12px] 1xs:text-[14px] md:text-[18px max-w-[460px]">
          Enter a referral code to earn 4 CPV for any GPU transaction. Otherwise, click 'Continue' to proceed with
          account creation. For a personalized code and support, Email: contactus@powermeta.cloud OR Telegram:
          Power_Meta
        </div>

      </div>
    </div>
    <div class="flex flex-col  gap-[10px]">
      <div class="inputHeader text-[18px]">
        Referral Code
      </div>

      <div class="inputContainer max-w-[490px] flex flex-row  relative">
        <div class="flex w-full  lg:w-9/12">

          <input v-if="!outcome" type="email" v-model="inviteCode" class="bg-transparent p-[20px] w-full  " size="large"
            :placeholder="isReferralCodeApplied ? 'redeemed' : 'Enter Code'" />
          <input v-else type="email" v-model="inviteCode" class="bg-transparent p-[20px] w-full  " size="large"
            placeholder="redeemed" />
        </div>
        <div class="flex hidden lg:block">

          <button v-if="!outcome" @click..prevent="applyReferralCode(inviteCode)"
            class=" absolute  verifyContainer right-[20px] top-1/2 transform -translate-y-1/2 cursor-pointer text-white  w-[104px] h-[44px] text-[16px]">
            <div class=" w-full ">
              Redeem
            </div>
          </button>
          <button v-else @click..prevent
            class=" absolute  verifyContainer right-[20px] top-1/2 transform -translate-y-1/2 cursor-pointer text-white  w-[104px] h-[44px] text-[16px]">
            <div class="w-full">
              Redeemed
            </div>
          </button>
        </div>
      </div>
      <div class="flex block lg:hidden">

        <button v-if="!outcome" @click..prevent="applyReferralCode(inviteCode)"
          class="   verifyContainer right-[20px] cursor-pointer text-white w-[104px] h-[44px] text-[16px] ">
          <div class="w-full">
            Redeem
          </div>
        </button>
        <button v-else @click..prevent
          class="   verifyContainer right-[20px] cursor-pointer text-white  w-[104px] h-[44px]  ">
          <div class="w-full">text-[16px]
            Redeemed
          </div>
        </button>
      </div>
    </div>
    <div v-if="!outcome" class="buttonContainer buttonText text-[.85rem] 2xs:text-[18px]  cursor-pointer max-w-[490px]"
      @click="continuetoHome">
      {{ $t("referral.continue-without") }}
    </div>
    <div v-else class="buttonContainer buttonText cursor-pointer text-[.85rem] 2xs:text-[18px] max-w-[490px]"
      @click="continuetoHome">
      {{ $t("common.continue") }}
    </div>
  </div>

</template>

<script lang="ts">
import request from "@/common/http";
import { ElMessage } from "element-plus";
import { $context, $user } from "@/store";
import ReferralCodeModal from "./ReferralCodeModal.vue";

export default {
  name: "ReferralCodeView",
  components: {
    ReferralCodeModal,
  },
  props: {
    step: { type: Number },
  },
  data() {
    return {
      showReferralModal: false,
      inviteCode: "",
      isReferralCodeApplied: false,
      referralDialogVisible: false,
      outcome: false,
      $user,
      walletAccount: false,
      count: 0,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);

    (this as any).inviteCode = urlParams.get("redirect");

    if (localStorage.getItem("_ApplyBonus")) {
      (this as any).outcome = true;
    }
    localStorage.removeItem("newWalletUser");
    (this as any).walletAccount = true;
    localStorage.setItem("onboardingPhase", "0");
    localStorage.setItem("_optional", "1");
    (this as any).showReferralModal = false;
  },

  methods: {
    continuetoHome() {
      (this as any).$router.go(0);
      (this as any).$router.push("/optional");
    },
    closeReferralModal() {
      (this as any).showReferralModal = false;
    },
    continueOnboarding() {
      (this as any).$emit("continue-onboarding");
    },

    applyReferralCode(referralCode) {
      if (referralCode) {
        (this as any).inviteCode = referralCode;
        this.bindInvite();
      } else {
        ElMessage.warning("Enter the invite code");
      }
    },

    bindInvite() {
      if (!(this as any).inviteCode) {
        ElMessage.warning((this as any).$t("invite.the-invite-code-is-empty"));
        return;
      }
      if ($user.isLogined) {
        (this as any).bind();
      } else {
        ElMessage.warning((this as any).$t("common.please-login"));
        (this as any).$router.push(
          "/login?redirect=" + encodeURIComponent(location.href)
        );
      }
    },
    async bind() {
      try {
        const trimInviteCode = (this as any).inviteCode.trim();
        if ($user.loginWithWallet) {
          const data: any = await request.post("/user/get_nonce", {
            wallet_addr: $user.account,
            nonce_type: "invite",
          });

          const nonce = data.nonce;

          const signResult = await ($context.web3 as any).eth.personal.sign(
            nonce,
            $user.account
          );

          const loginResult = await request.postFormData(
            "/user/binding_invite",
            {
              wallet_addr: data.wallet_addr,
              signature: signResult,
              nonce: nonce,
              invite_code: trimInviteCode,
            }
          );
        } else {
          await request.postFormData("/user/binding_invite", {
            wallet_addr: $user.account,
            invite_code: trimInviteCode,
          });
        }

        localStorage.setItem("_ApplyBonus", "1");
        (this as any).isReferralCodeApplied = true;
        (this as any).outcome = true;
        (this as any).showReferralModal = true;

        ElMessage({
          message: (this as any).$t("invite.bind-success"),
          type: "success",
        });
      } catch {
        (this as any).showReferralModal = true;
      }
    },

    updateInviteCode() {
      (this as any).inviteCode = (this as any).inviteCodeInput;
      (this as any).isReferralCodeApplied =
        (this as any).inviteCodeInput.length > 0;
    },

    goBack() {
      (this as any).step = 2;
    },
  },
};
</script>
<style scoped>
.inputContainer {
  border-radius: 18px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.inputHeader {
  color: var(--ffffff, #FFF);

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.inputContainer input {
  color: white;
}

.inputContainer input::placeholder {
  color: grey;
}

.verifyContainer {
  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.referralContainer {
  display: flex;
  flex-direction: column;
}

.referralHeader {
  color: var(--ffffff, #FFF);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.referralSubHeader {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}


textarea:focus,
input:focus {
  outline: none;
}

.header {
  color: #35312e;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
}



.buttonText {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

}
</style>
