<template>
  <div class="mainContainer w-full h-full  ">
    <div class="subContainer w-full ">

      <div class="profileContainer ">
        <div class="!w-[150px] !h-[150px] rounded-full ">
          <img :src="$user.avatarPath" class="rounded-full" v-if="$user.avatarPath" alt="avatar" />
          <div class="rounded-full bg-white !w-[150px] !h-[150px] " v-else>
            <img src="@/assets/images/icon-personal.svg" class="rounded-full" alt="avatar" />
          </div>
        </div>
        <div class="personalText text-[18px] 2xl:text-[24px]">
          Personal
        </div>
        <div class="accountText text-[16px] 2xl:text-[22px]">
          {{ $user?.account?.slice(0, 4) }}...{{
            $user?.account?.slice(-4)
          }}
        </div>
      </div>
      <div class="sectionLine">
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/myprofile')">
        <div>
          <img src="@/assets/images/icon-profile.svg" :alt="$t('profile.information')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          {{ $t("profile.information") }}
        </div>
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/ai-dashboard')">
        <div>
          <img src="@/assets/images/icon-dashboard.svg" :alt="$t('profile.dashboard')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          AI Factory {{ $t("profile.dashboard") }}
        </div>
      </div>
      <div v-show="efilBalance > 0" class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/order')">
        <div>
          <img src="@/assets/images/icon-dashboard.svg" :alt="$t('profile.dashboard')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          Filecoin {{ $t("profile.dashboard") }}
        </div>
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/invitation')">
        <div>
          <img src="@/assets/images/icon-refer-a-friend.svg" :alt="$t('profile.refer-a-friend')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          {{ $t("profile.refer-a-friend") }}
        </div>
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/ambassador')">
        <div>
          <img src="@/assets/images/icon-ambassador-program.svg" :alt="$t('profile.ambasador-program')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          {{ $t("profile.ambasador-program") }}
        </div>
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer" v-show="ambVisible"
        @click="pushLink('/ambassador-rewards')">
        <div>
          <img src="@/assets/images/icon-ambassador-rewards.svg" :alt="$t('profile.ambasador-rewards')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          {{ $t("profile.ambasador-rewards") }}
        </div>
      </div>
      <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer"
        @click="pushLink('/security')">
        <div>
          <img src="@/assets/images/icon-security.svg" :alt="$t('security.settings')"
            class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
        </div>
        <div class="textContainer text-[21px] 2xl:text-[21px]">
          {{ $t("security.settings") }}
        </div>
      </div>
      <div class="sectionLine">
      </div>
      <el-popconfirm title="Are you sure to logout?" width="240px" @confirm="logout">
        <template #reference>
          <div class="flex flex-row  w-full gap-[15px] justify-start items-center cursor-pointer">
            <img src="@/assets/images/logout.svg" alt="Logout" class="h-[20px] w-[20px] 2xl:h-[30px] 2xl:w-[30px]" />
            <div class="textContainer text-[21px] 2xl:text-[21px]">
              {{ $t("profile.logout") }}
            </div>
          </div>
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>
<script lang="ts">
import request from "@/common/http";
import { $user, pageConfig } from "../store/index";
import { $context } from "@/store";
import BigNumber from "bignumber.js";
import {
  getEFilTokenContract
} from "@/contracts";

export default {
  data() {
    return {
      ambVisible: false,
      pageConfig,
      $user,
      efilBalance: 0
    };
  },
  mounted() {
    (this as any).getWalletBalance();
    (this as any).checkAMBVisible();
  },
  methods: {
    pushLink(path: string) {
      (this as any).$router.push(path);
      (this as any).$root.showDrawer = false;
    },
    async logout() {
      (this as any).$root.logout();
    },
    async checkAMBVisible() {
      (this as any).ambVisible = await request.get("/agent/visible");
    },
    async getWalletBalance() {
      if ($user.loginWithWallet) {
        const _18Number = 1000000000000000000;
        getEFilTokenContract()
          .methods.balanceOf($user.account)
          .call()
          .then((res: BigNumber, err: any) => {
            (this as any).efilBalance = new BigNumber(res)

              .dividedBy(_18Number)
              .toString(10);
          })
          .catch((err: any) => {
            console.error(err);
          });


      } else {
        const transResult: any = await request.get("/user/trans_info");
        if (transResult) {
          (this as any).efilBalance = Number(transResult.efil_balance);

        }
      }
    },

  },
};
</script>
<style scoped>
.mainContainer {
  background: rgba(255, 255, 255, 0.20);
  /* Semi-transparent background */
  backdrop-filter: blur(15px);
  /* Blur effect */
  -webkit-backdrop-filter: blur(15px);
  /* For Safari */
  display: flex;
  /* Ensure it covers the area you want blurred */
  position: absolute;
  /* or 'fixed' depending on use case */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.subContainer {
  display: flex;

  padding: 40px 30px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.personalText {
  color: #FFF;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.accountText {
  color: #FFF;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionLine {
  width: 397px;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
}

.textContainer {
  color: #FFF;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
