<template>


  <div class="">
    <div class="menu-mobile bgContainer  absolute textContainer ">
      <div class=" flex flex-col gap-[30px]  px-10  leading-10">

        <el-collapse>
          <el-row class="">
            <el-col :span="24">
              <div @click="goLink('/ai-factory')" class="font-bold">
                <span>AI Factory</span>
              </div>
            </el-col>
          </el-row>
        </el-collapse>

        <el-divider class="!my-2 !border-[#e2dad6]" />
        <el-row class="">
          <el-col :span="24">
            <div @click="goLink('/swap')" class="font-bold">
              <span>Withdraw</span>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row @click="">
                    <el-col :span="16">
                        {{ $t("common.menu.resources") }}
                    </el-col>
                    <el-col :span="8" class="text-right">
                        <el-icon class="el-icon--right"><arrow-right /></el-icon>
                    </el-col>
                </el-row> -->
        <el-divider class="!my-2 !border-[#e2dad6]" />
        <el-row class="">
          <el-col :span="24">
            <div @click="goLink('/about')" class="font-bold">
              <span>{{
              $t("common.menu.about")
            }}</span>
            </div>
          </el-col>
        </el-row>



        <!-- <el-collapse>
          <el-collapse-item name="1">
            <template #title>
              <div class=" textContainer">{{
                $t("common.menu.about")
              }}</div>
            </template>

</el-collapse-item>
</el-collapse> -->
        <el-divider class="!my-2 !border-[#e2dad6]" />
        <!-- <el-collapse>
                    <el-collapse-item name="1">
                        <template #title>
                            <span class="text-black text-base">{{ $t('common.menu.contact') }}</span>
                        </template>
                        <div class="flex flex-col gap-2 text-black pl-4 pt-6 text-base">
                            <el-row>
                                <el-col :span="24">
                                    <div @click="goLink('/contact')" class="font-bold">
                                        {{$t("common.menu.support.contact-us")}}
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-collapse-item>
                </el-collapse> -->
        <el-row>
          <el-col :span="24">
            <div @click="goLink('/contact')" class="font-bold">
              <span>{{ $t("common.menu.support.contact-us") }}</span>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row @click="">
                    <el-col :span="16">
                        {{ $t("common.menu.support") }}
                    </el-col>
                    <el-col :span="8" class="text-right">
                        <el-icon class="el-icon--right"><arrow-right /></el-icon>
                    </el-col>
                </el-row> -->
        <el-divider class="!my-2 !border-[#e2dad6]" />
        <el-row>
          <el-col :span="24">
            <div @click="goLink('/node-info')" class="font-bold">
              <span>{{ $t("common.menu.nodes") }}</span>
            </div>
          </el-col>
        </el-row>
        <el-divider class="!my-2 !border-[#e2dad6]" />
        <el-row>
          <el-col :span="24">
            <div @click="goLink('/wallet')" class="font-bold">
              <span>{{ $t("common.menu.wallet") }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { pageConfig } from "../store/index";
import promoPDF from "@/assets/pdfs/promo.pdf";
export default {
  name: "MenuMobile",
  props: {
    currentMenu: String,
  },
  data() {
    return {
      pageConfig,
    };
  },
  methods: {
    goLink(link: string) {
      ((this as any).$parent as any).closeMenu();
      if (link === "https://www.araratglobal-blog.com/") {
        window.open(link, "_blank");
      } else {
        (this as any).$router.push(link);
      }
    },
  },
};
</script>

<style>
.menu-mobile .el-collapse-item__header,
.menu-mobile .el-collapse-item__wrap,
.menu-mobile .el-collapse {
  border-bottom: 0;
  border-top: 0;
  background-color: transparent;
}

.menu-mobile {
  top: 97px;
  z-index: 2000;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  height: fit-content;
}

.mobile-menu-bg {
  top: 97px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 2000;
  height: 100%;
  background-color: #1E1E1E;
}

.bgContainer {
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(25px);

}

.textContainer {
  color: #FFF;
  font-family: "Work Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
