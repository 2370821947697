<template>
  <div v-if="showSuccess" class="modal-backdrop">
    <div class="modal-container  px-[10px] py-[30px]  sm:px-[50px] sm:py-[30px] max-w-[700px]">

      <div class="text-[24px] md:text-[32px] text-white mx-auto font-bold  text-center">
        <div>{{ $t("email.settings-updated") }}</div>
        <div>{{ $t("email.successfully") }}</div>
      </div>
      <div
        class="w-10/12 flex justify-center h-8 items-center font-bold text-white text-[18px] mb-4 mx-10 cursor-pointer p-5 rounded-2xl bg-gradient-to-r from-btn-blue to-btn-lightblue"
        @click="closeModal()">
        <span class="font-bold"> {{ $t("common.continue") }}</span>
      </div>

    </div>
  </div>
  <div v-else class="modal-backdrop">



    <div class="modal-container  px-[10px] py-[30px]  sm:px-[50px] sm:py-[30px] max-w-[700px]">

      <div class="text-[24px] md:text-[32px] text-white mx-auto font-bold  text-center">

        <div> {{ $t("email.settings-failed") }}</div>
      </div>
      <div class="text-[18px]  text-white mx-auto font-bold  text-center">
        <div> {{ $t("common.try.again") }}</div>
        {{ $t("common.contact") }}
      </div>
      <div
        class="w-10/12 flex justify-center h-8 items-center font-bold text-white text-[18px] mb-4 mx-10 cursor-pointer p-5 rounded-2xl bg-gradient-to-r from-btn-blue to-btn-lightblue"
        @click="closeModal()">
        <span class="font-bold"> {{ $t("common.continue") }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "EmailNotificationsModal",
  props: {
    result: {
      type: Boolean,
    },
    accountSetupLocation: {
      type: Boolean,
    },
    transactionOpt: {
      type: Boolean,
    },
    newsletterOpt: {
      type: Boolean,
    },
  },
  data() {
    return {
      showSuccess: true,
    };
  },
  mounted() {
    if (!(this as any).result == true) {
      (this as any).showSuccess = false;
    }
  },
  methods: {
    closeModal() {
      if ((this as any).accountSetupLocation && !(this as any).result) {
        this.reopen();
      } else {
        ((this as any).$parent as any).closeReferralModal();
      }
    },
    reopen() {
      (this as any).$emit("reopen-notifcations-modal");
    },
  },
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  display: flex;
  width: 1007px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(50px);
  gap: 20px;
}
</style>
