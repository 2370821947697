<template>

  <div class="max-w-con px-[10px] sm:px-[40px] md:px-[50px]  pb-12 lg:pb-[160px] ">
    <div class="flex flex-col">
      <div class="flex flex-row gap-[20px]">
        <div class=" headContainer ">
          <img src="@/assets/images/user-octagon.svg" alt="user icon" class="w-[60px] h-[60px]" />
        </div>
        <div class=" flex-col text-white">
          <div class="accountSetup"> Account Set up:</div>
          <div class="header !text-[28px] sm:!text-[36px]"> Transaction Passcode</div>
        </div>
      </div>
      <div
        class="subHeaderLight text-[12px] 1xs:text-[14px] md:text-[18px] mt-[20px] pb-[20px] 1xs:pb-[30px] md:pb-[50px]">
        Set up a transaction passcode to
        authorize and validate any
        transactions
        made through
        your account.</div>

    </div>

    <div class="transactionContainer  p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px] ">
      <div class="transactionPasscode text-[20px]  sm:text-[28px]">
        Set Transaction Passcode
      </div>
      <div class="flex flex-col w-full">
        <div class="subHeader pb-[10px]  text-[16px] 1xs:text-[14px] md:text-[18px]">
          Set New Transaction Passcode (six numbers)
        </div>
        <div class="inputContainer">
          <input type="password" v-model="password" class="bg-transparent text-white w-full" :maxlength="6"
            :placeholder="setConfirmed === false
            ? $t('common.tips.please-input')
            : `Completed`
            " />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="subHeader pb-[10px] text-[16px] md:text-[18px]">
          Repeat Transaction Passcode
        </div>
        <div class="inputContainer">
          <input type="password" v-model="confirmPassword" class="bg-transparent text-white w-full" :maxlength="6"
            :placeholder="setConfirmed === false
            ? $t('common.tips.please-input')
            : `Completed`
            " />
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="subHeader pb-[10px] text-[16px] md:text-[18px]">
          E-mail Verification Code
        </div>

        <div class="inputContainer relative">
          <input type="text" v-model="verifyCode" class="bg-transparent text-white w-full" :maxlength="6"
            :placeholder="setConfirmed === false ? $t('common.tips.please-input') : 'Completed'" />
          <button @click="getVerifyCode($user.email, 'pass')" :class="{ 'verifyContainer': remainSecond <= 0 }"
            class="absolute right-[20px] top-1/2 transform -translate-y-1/2 cursor-pointer text-white w-[104px] h-[44px] text-[16px]">
            <div class="w-full">
              Get Code
            </div>
          </button>
        </div>
      </div>
      <div v-if="setConfirmed" class="buttonContainer cursor-pointer text-white w-full" @click="continueOnboarding">
        <div class="continue">
          {{ $t("common.continue") }}

        </div>
      </div>
      <div v-else @click="modifyPassword" class="buttonContainer cursor-pointer text-white w-full   ">
        <div class="continue">
          {{ $t("common.continue") }}

        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import request from "@/common/http";
import { getEncryptStr, toNFix } from "@/common/utils";
import { $context, $user } from "@/store";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import TransactionModal from "./TransactionModal.vue";

export default {
  name: "TransactionPasscode",
  components: {
    TransactionModal,
  },
  data() {
    return {
      showTransactionModal: false,
      setConfirmed: false,
      inputEmail: ref(""),
      walletInfo: null,
      $context,
      $user,
      setPwdDialogVisible: false,
      resetPwdDialogVisible: false,
      passwordDialogVisible: false,
      setCaptchaDialogVisible: false,
      verifyKeyDialogVisible: false,
      reviseEmailDialogVisible: false,
      reviewingEmailDialogVisible: false,
      email: "",
      verifyCode: "",
      remainSecond: 0,
      oldPassword: "",
      password: "",
      confirmPassword: "",
      resetNewPwd: "",
      resetRepeatPwd: "",
      resetVerifyCode: "",
      transPassword: "",
      googleAuthSecret: null,
      googleVerifyCode: "",
      googleCodeKey: "",
      reviseEmailForm: {
        password: "",
        privateKey: "",
        email: "",
        emailCode: "",
        reason: "",
      },
      reviewInfo: null,
    };
  },
  mounted() {
    localStorage.setItem("onboardingPhase", "2");
    if ($user.isLogined) {
      (this as any).getWalletInfo();
      (this as any).transactionPhaseCheck();
    } else {
      ElMessage.warning((this as any).$t("common.please-login"));
      (this as any).$router.push("/login");
    }
  },
  watch: {
    remainSecond(newValue: number, oldValue: number) {
      if (newValue == 0) {
        return;
      }
      (this as any).remainSecond > 0 &&
        setTimeout(() => ((this as any).remainSecond -= 1), 1000);
    },
  },
  methods: {
    async transactionPhaseCheck() {
      const onboardingCheck: any = await request.get("/wallet/info");
      if (onboardingCheck.password_is_set === true) {
        (this as any).setConfirmed = true;
      }
    },
    continueOnboarding() {
      (this as any).$emit("continue-onboarding-to-google");
    },
    toNFix,
    async getVerifyCode(emailAddr, type) {
      if (!emailAddr) {
        ElMessage.warning(
          (this as any).$t("wallet.please-enter-your-email-addresss")
        );
        return;
      }
      if ((this as any).remainSecond > 0) {
        return;
      }

      await request.postFormData("/user/get_code", {
        email: emailAddr,
        code_type: type,
      });

      (this as any).remainSecond = 60;
      ElMessage.success((this as any).$t("common.tips.verification-code-sent"));
    },
    async getResetVerifyCode() {
      if ((this as any).remainSecond > 0) {
        return;
      }

      await request.postFormData("/user/get_code", {
        email: $user.email,
        code_type: "reset_pass",
      });

      (this as any).remainSecond = 60;

      ElMessage.success((this as any).$t("common.tips.verification-code-sent"));
    },
    openReviseEmailDialog() {
      (this as any).reviseEmailDialogVisible = true;
      (this as any).reviseEmailForm.password = "";
      (this as any).reviseEmailForm.privateKey = "";
      (this as any).reviseEmailForm.email = "";
      (this as any).reviseEmailForm.emailCode = "";
      (this as any).reviseEmailForm.reason = "";
    },

    openPasswordDialog() {
      (this as any).setPwdDialogVisible = true;
      (this as any).password = "";
      (this as any).confirmPassword = "";
      (this as any).verifyCode = "";
      (this as any).oldPassword = "";
    },
    openResetPasswordDialog() {
      (this as any).resetPwdDialogVisible = true;
      (this as any).resetNewPwd = "";
      (this as any).resetRepeatPwd = "";
      (this as any).resetVerifyCode = "";
    },
    async modifyPassword() {
      if ((this as any).setConfirmed) {
      }

      if (!(this as any).password) {
        ElMessage.error((this as any).$t("wallet.please-enter-password"));
        return;
      }
      if (
        !(this as any).confirmPassword ||
        (this as any).password != (this as any).confirmPassword
      ) {
        ElMessage.error(
          (this as any).$t("wallet.please-enter-correct-password")
        );
        return;
      }
      if (!(this as any).verifyCode) {
        ElMessage.error(
          (this as any).$t("common.tips.please-enter-verification-code")
        );
        return;
      }
      try {
        const result = await request.postFormData("/wallet/set_password", {
          password: getEncryptStr((this as any).password),
          confirm_password: getEncryptStr((this as any).confirmPassword),
          email_code: (this as any).verifyCode,
        });
        if (result) {
          ElMessage.success((this as any).$t("wallet.password-set-successed"));
          (this as any).setConfirmed = true;
          (this as any).continueOnboarding();
        }

      } catch {

        ElMessage.error((this as any).$t('wallet.password-set-failed'));
      }
    },
    async getWalletInfo() {
      const result: any = await request.get("/wallet/info");
      if (result) {
        (this as any).walletInfo = result;
        if (
          result?.email_review_status === 3 ||
          result?.email_review_status === 1
        ) {
          (this as any).getReviewInfo();
        }
      }
    },
  },
};
</script>

<style scoped>
.accountSetup {
  color: #FFF;
  opacity: 0.8;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.headContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.subHeaderLight {
  color: #FFF;
  opacity: 0.5;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.subHeader {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

}

.verifyContainer {

  justify-items: center;
  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.verifyContainerDisabled {

  text-align: center;

  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: gray;
}

.header {
  color: #FFF;
  font-family: "Work Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.transactionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
}

.transactionPasscode {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inputContainer {
  display: flex;
  height: 70px;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.continue {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

input {
  outline: none;
}
</style>
