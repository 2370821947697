<template>
  <div class="flex flex-col lg:flex-row bg-[#05040E]  ">



    <div v-if="showOnboarding" class="pb-[30px]">
      <div v-if="phase >= 2" class="m-auto  w-full">
        <div class="flex m-auto navigationContainer max-w-con bg-transparent  xl:gap-[80px]">
          <div class="singleButtonContainer text-[10px]  2xs:text-[12px] md:text-[18px]   ">

            <div :class="phase === 1 ? 'numberDisplayActive ' : 'numberDisplay'"
              class="  w-[25px] h-[25px] md:w-[48px] md:h-[48px]   md:px-[5px] md:py-[15px] gap-[10px]  buttonNumber">
              1
            </div>
            <div :class="phase === 1 ? 'phaseTextActive ' : 'phaseText'">
              Passcode
            </div>
          </div>
          <div class="singleButtonContainer text-[10px]  2xs:text-[12px] md:text-[18px] ">
            <div :class="phase === 2 ? 'numberDisplayActive' : 'numberDisplay'"
              class="  w-[25px] h-[25px]  md:w-[48px] md:h-[48px]   md:px-[5px] md:py-[15px] gap-[10px]  buttonNumber">
              2
            </div>
            <div :class="phase === 2 ? 'phaseTextActive ' : 'phaseText'">
              Authentication
            </div>
          </div>
          <div class="singleButtonContainer text-[10px]  2xs:text-[12px] md:text-[18px]  ">

            <div :class="phase === 3 ? 'numberDisplayActive' : 'numberDisplay'"
              class=" w-[25px] h-[25px]  md:w-[48px] md:h-[48px]   md:px-[5px] md:py-[15px] gap-[10px]  buttonNumber">
              3
            </div>
            <div :class="phase === 3 ? 'phaseTextActive ' : 'phaseText'">
              Backup
            </div>
          </div>
          <div class="singleButtonContainer text-[10px]  2xs:text-[12px] md:text-[18px]  ">
            <div :class="phase === 4 || phase === 5 ? 'numberDisplayActive' : 'numberDisplay'"
              class="  w-[25px] h-[25px] md:w-[48px] md:h-[48px]   md:px-[5px] md:py-[15px] gap-[10px]  buttonNumber">
              4
            </div>
            <div :class="phase === 4 || phase === 5 ? 'phaseTextActive ' : 'phaseText'">
              Verification
            </div>
          </div>
          <div class="singleButtonContainer text-[10px]  2xs:text-[12px] md:text-[18px] ">

            <div :class="phase === 6 ? 'numberDisplayActive' : 'numberDisplay'"
              class="  w-[25px] h-[25px] md:w-[48px] md:h-[48px]   md:px-[5px] md:py-[15px] gap-[10px]  buttonNumber">
              5
            </div>
            <div :class="phase === 6 ? 'phaseTextActive ' : 'phaseText'">
              Review
            </div>
          </div>

        </div>
      </div>

      <div class="flex flex-row w-full w-screen h-full  pb-12 lg:pb-[160px] min-h-[calc(100vh-415px)]"
        v-if="phase === 0">
        <div class="hidden lg:block lg:w-1/2 ">
          <div class="lg:bg-login-img bg-no-repeat bg-contain w-full h-full  "></div>
        </div>
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-full lg:w-1/2  h-full h-screen">
          <div class="flex flex-col  mt-[25px] lg:mt-0 lg:mb-[75px]  xl:mt-[50px] w-full ">

            <ReferralCode class="  flex   px-[10px]  sm:px-[40px] md:px-[50px]  xl:px-0 " />
          </div>
        </div>
      </div>


      <div v-if="phase === 1">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen min-h-[calc(100vh-368px)]">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <NotificationsAndUpdates @continue-onboarding="afterOptional" />
          </div>
        </div>
      </div>



      <div v-else-if="phase === 2">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen  min-h-[calc(100vh-465px)] ">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <TransactionPasscode @continue-onboarding-to-google="afterTransaction" />
          </div>
        </div>
      </div>
      <div v-else-if="phase === 3">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen  min-h-[calc(100vh-465px)] ">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <googleAuth @continue-onboarding-to-backupKey="afterGoogleAuth" />
          </div>
        </div>
      </div>


      <div v-else-if="phase === 4">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen  min-h-[calc(100vh-465px)]">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <BackupPrivateKey @backupStep="nextBackupStep" @continue-onboarding-to-confirmation="afterBackupKey" />
          </div>
        </div>
      </div>
      <div v-else-if="phase === 5">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen  min-h-[calc(100vh-465px)]">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <BackupPrivateKey @continue-onboarding-to-confirmation="afterBackupKey" :skip="true" />
          </div>
        </div>
      </div>
      <div v-else-if="phase === 6">
        <div class="flex flex-col lg:flex-row bg-[#05040E] bg-components w-screen  min-h-[calc(100vh-465px)]">
          <div class="flex flex-col mx-auto mt-[20px] sm:mt-[50px]">
            <ReviewAccountSetup @complete-onboarding="completeOnboardings" />
          </div>
        </div>

      </div>
    </div>





    <!--
      <div v-if="phase > 1" class="max-w-con flex bg-[#fbfbfb] border-x border-b border-[#DFDFDF] h-32">
        <div class="flex sm:gap-1 sm:gap-3 max-w-con mt-10">
          <button
            class="rounded-md w-8 h-8  sm:h-12 sm:w-12 sm:mr-4 flex justify-center items-center text-[#8181A5] bg-[#F5F5FA]"
            @click="pastPhase">
            &lt;
          </button>
          <button :class="[
      'border-2 rounded-md w-8 h-8 sm:h-12 sm:w-12 gap-4 flex justify-center items-center bg-white',
      { 'font-semibold': phase === 2 },
    ]" @click="phase = 2">
            1
          </button>
          <button :class="[
      'border-2 rounded-md  w-8 h-8 sm:h-12 sm:w-12  flex justify-center items-center bg-white',
      { 'font-semibold': phase === 3 },
    ]" @click="phase = 3">
            2
          </button>
          <button :class="[
      'border-2 rounded-md  w-8 h-8 sm:h-12 sm:w-12  flex justify-center items-center bg-white',
      { 'font-semibold': phase === 4 },
    ]" @click="phase = 4">
            3
          </button>
          <button :class="[
      'border-2 rounded-md w-8 h-8 sm:h-12 sm:w-12 flex justify-center items-center bg-white',
      { 'font-semibold': phase === 5 },
    ]" @click="phase = 5">
            4
          </button>
          <button :class="[
      'border-2 rounded-md w-8 h-8 sm:h-12 sm:w-12  flex justify-center items-center bg-white',
      { 'font-semibold': phase === 6 },
    ]" @click="phase = 6">
            5
          </button>
          <button
            class="border-2 rounded-md sm:ml-4 w-8 h-8 sm:h-12 sm:w-12 flex justify-center items-center text-[#8181A5] bg-[#F5F5FA]"
            @click="nextPhase">
            >
          </button>
        </div>
      </div>
    </div>

    <div v-else></div>

    <div class="bg-black" v-if="phase === 10"></div> -->
  </div>

</template>

<script lang="ts">
import request from "@/common/http";
import { $user } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";
import BackupPrivateKey from "../components/BackupPrivateKey.vue";
import ReferralCode from "../components/ReferralCode.vue";
import TransactionPasscode from "../components/TransactionPasscode.vue";
import googleAuth from "../components/googleAuth.vue";
import ReviewAccountSetup from "@/components/ReviewAccountSetup.vue";
import NotificationsAndUpdates from "./NotificationsAndUpdates.vue";
export default {
  name: "OnboardingView",
  props: {
    showOnboarding: {
      type: Boolean,
    },
  },
  components: {
    BackupPrivateKey,
    ReferralCode,
    TransactionPasscode,
    googleAuth,
    ReviewAccountSetup,
    NotificationsAndUpdates,
  },
  data() {
    return {
      skip: true,
      phase: 10,
      $user,
      onboardingVisible: false,
      showBackupDialogs: false,
    };
  },

  mounted() {
    if (sessionStorage.getItem("onboarding")) {
      sessionStorage.removeItem("onboarding");
    }
    if ((this as any).onboardingVisible) {
      history.pushState(null, "", location.href);
      window.onpopstate = function () {
        history.go(1);
      };
    } else {
      history.pushState(null, "", location.href);
      window.onpopstate = function () {
        history.go(0);
      };
    }

    (this as any).onboardingChecker();
    (this as any).onboardingComplete();
    if (!(this as any).onboardingVisible && localStorage.getItem("_new")) {
      (this as any).$router.push("/");
    }
    if ((this as any).onboardingVisible) {
      ElMessage.success({
        message: "Follow these steps to secure your account",
        duration: 0, // Set duration to 0 for manual close
        showClose: true, // Show the close button
      });
    }
  },
  watch: {
    showOnboarding(newValue: Boolean) {
      if (newValue === true) {
        const storedPhase = localStorage.getItem("onboardingPhase");
        if (storedPhase !== null) {
          (this as any).phase = parseInt(storedPhase, 10);
        } else {
          (this as any).phase = 0;
        }

        (this as any).onboardingVisible = true;
        (this as any).showBackupDialogs = true;
      } else {
        (this as any).onboardingVisible = false;
        (this as any).showBackupDialogs = false;
      }
    },
  },
  methods: {

    async onboardingComplete() {
      if ($user.isLogined) {
        const onboardingCheck: any = await request.get("/wallet/info");
        if (
          onboardingCheck.password_is_set &&
          onboardingCheck.google_code_isset &&
          onboardingCheck.private_key_backup
        ) {
          (this as any).$router.push("/");
        }
      }
    },
    async onboardingChecker() {
      if ($user.isLogined) {
        const onboardingCheck: any = await request.get("/wallet/info");
        if (
          onboardingCheck.password_is_set === false ||
          onboardingCheck.google_code_isset === false ||
          onboardingCheck.private_key_backup === false
        ) {
          (this as any).onboardingVisible = true;
        } else {
          (this as any).$router.push("/");
        }
      }
    },
    nextPhase() {
      if ((this as any).phase < 6) {
        (this as any).phase++;
      } else {
        (this as any).phase = 6;
      }
      localStorage.setItem("onboardingPhase", (this as any).phase.toString());
    },
    pastPhase() {
      if ((this as any).phase > 2) {
        (this as any).phase--;
      } else {
        (this as any).phase = 2;
      }
      localStorage.setItem("onboardingPhase", (this as any).phase.toString());
    },

    afterOptional() {
      (this as any).phase = 2;
    },
    afterTransaction() {
      (this as any).phase = 3;
    },
    afterGoogleAuth() {
      (this as any).phase = 4;
    },
    nextBackupStep() {
      (this as any).phase = 5;
      (this as any).step = 2;
    },
    afterBackupKey() {
      (this as any).phase = 6;
    },
    completeOptionalOnboardings() {
      (this as any).phase = 3;
    },
    completeOnboardings() {
      (this as any).onboardingVisible = false;
    },
    goBack() {
      (this as any).phase = (this as any).phase - 1;
    },
  },
};
</script>
<style scoped>
.navigationContainer {
  display: flex;

  justify-content: center;
  align-items: center;
}

.numberDisplayActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.numberDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.17);
}

.phaseText {

  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  opacity: 0.5;

}

.phaseTextActive {


  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.bg-components {
  background-image: url('@/assets/images/blur.svg');
  background-size: contain;
  background-repeat: no-repeat;

}

.setupHeader {
  color: rgba(53, 49, 46, 0.2);
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
}

.setupPage {
  color: #35312e;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.92px;
}

.singleButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
  flex: 1 0 0;
}

.bg-components {
  background-image: url('@/assets/images/blur.svg');
  background-size: cover;
  background-position: center;

  background-repeat: no-repeat;

}

.buttonNumber {
  color: var(--ffffff, #FFF);

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

}

.login-bgs {
  background-image: url('@/assets/images/login-image.png');
  background-size: contain;
  /* or contain, depending on how you want to fit the image */
  background-repeat: no-repeat;
  background-position: center;
  /* Adjust as needed */
}
</style>
