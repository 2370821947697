<template>

  <div class="max-w-con px-[10px] sm:px-[40px] md:px-[50px]  pb-12 lg:pb-[160px] ">
    <div class="flex flex-col">
      <div class="flex flex-row gap-[20px]">
        <div class=" headContainer ">
          <img src="@/assets/images/user-octagon.svg" alt="user icon" class="w-[60px] h-[60px]" />
        </div>
        <div class=" flex-col text-white">
          <div class="subHeader text-[16px] 1xs:text-[14px] md:text-[18px]"> Account Set up:</div>
          <div class="header !text-[28px] sm:!text-[36px]"> Review Account</div>
        </div>
      </div>
      <div class="subHeaderLight text-[16px] 1xs:text-[14px] md:text-[18px] mt-[20px] mb-[50px]">Review the security
        settings of your Account</div>

    </div>

    <div class="reviewContainer p-[10px]  gap-[20px] 1xs:p-[30px] 1xs:gap-[30px] md:p-[50px]  md:gap-[50px] ">
      <div class="flex flex-col gap-[20px] borer w-full">
        <div class="flex flex-row  w-full justify-between items-center">
          <div class="reviewSubHeader text-[16px] 2xs:text-[20px]  sm:text-[28px]">
            Set transaction password
          </div>
          <div v-if="setPasscode" class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border  flex justify-center checkmarkContainer p-[3px]"> <img
                src="@/assets/images/checkmark.svg" />
            </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
          <div v-else class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border w-[18px] h-[18px] flex justify-center "> </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
        </div>
        <div class="describe text-[12px] 1xs:text-[14px] md:text-[18px]">
          Set up a transaction passcode to authorize and validate any transactions made through your account.
        </div>
      </div>
      <div class="flex flex-col gap-[20px] borer w-full">
        <div class="flex flex-row  w-full justify-between items-center">
          <div class="reviewSubHeader text-[16px] 2xs:text-[20px]  sm:text-[28px]">
            Set Google Authentication
          </div>
          <div v-if="setGoogleAuth" class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border  flex justify-center checkmarkContainer p-[3px]"> <img
                src="@/assets/images/checkmark.svg" />
            </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
          <div v-else class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border w-[18px] h-[18px] flex justify-center "> </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
        </div>
        <div class="describe text-[12px] 1xs:text-[14px] md:text-[18px]">
          Set up Google Authenticator to protect your wallet transactions through multi-factor authentication
        </div>
      </div>
      <div class="flex flex-col gap-[20px] borer w-full">
        <div class="flex flex-row  w-full justify-between items-center">
          <div class="reviewSubHeader text-[16px] 2xs:text-[20px]  sm:text-[28px]">
            Backup Account Private Key
          </div>
          <div v-if="setBackup" class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border  flex justify-center checkmarkContainer p-[3px]"> <img
                src="@/assets/images/checkmark.svg" />
            </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
          <div v-else class="flex flex-row justify-center items-center gap-[4px]">
            <div class="border w-[18px] h-[18px] flex justify-center "> </div>
            <div class="required">
              {{ $t("common.required") }}
            </div>
          </div>
        </div>
        <div class="describe text-[12px] 1xs:text-[14px] md:text-[18px]">
          Back up the private key of your Ararat wallet account to ensure access to your account.
        </div>
      </div>

      <div v-if="completed" class="w-full cursor-pointer buttonContainer text-white" @click="redirect">
        <div class="continue">

        </div>
        {{ $t("common.complete") }}
      </div>
      <div v-else class="w-full buttonContainerDisable cursor-not-allowed text-white" @click..prevent>
        <div class="continue">

          {{ $t("common.complete") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import request from "@/common/http";
import { $user } from "@/store";
import { ElMessage } from "element-plus";

export default {
  name: "ReviewAccountSetup",
  data() {
    return {
      setBackup: false,
      setGoogleAuth: false,
      setPasscode: false,
      completed: false,
      $user,
    };
  },
  mounted() {
    (this as any).onboardingCheck();
  },
  methods: {
    async onboardingCheck() {
      if ($user.isLogined) {
        const onboardingCheck: any = await request.get("/wallet/info");
        if (onboardingCheck.password_is_set) {
          (this as any).setPasscode = true;
        }
        if (onboardingCheck.google_code_isset) {
          (this as any).setGoogleAuth = true;
        }
        if (onboardingCheck.private_key_backup) {
          (this as any).setBackup = true;
        }
        if (
          (this as any).setBackup &&
          (this as any).setPasscode &&
          (this as any).setGoogleAuth
        ) {
          localStorage.setItem("_new", "1");
          (this as any).completed = true;
        }
        (this as any).onboardingVisible = true;
      }
    },

    goBack() {
      (this as any).step = 1;
    },
    redirect() {
      localStorage.setItem("_new", "1");
      (this as any).$emit("complete-onboarding");
      (this as any).$router.go(0);
      (this as any).$router.push("/optional");
    },
  },
};
</script>
<style scoped>
.headContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.required {
  color: #FFF;

  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.subHeaderLight {
  color: #FFF;
  opacity: 0.5;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.subHeader {
  color: #FFF;
  opacity: 0.8;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

}

.verifyContainer {

  justify-items: center;
  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.verifyContainerDisabled {

  text-align: center;

  display: flex;
  padding: 7px 13px;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: gray;
}

.header {
  color: #FFF;
  font-family: "Work Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.checkmarkContainer {
  border-radius: 4px;
  border: 1px solid #FFF;
  width: 18px;
  height: 18px;
}

.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.10);
}

.describe {
  color: #FFF;
  opacity: 0.7;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  align-self: stretch;
}

.reviewSubHeader {
  color: #FFF;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inputContainer {
  display: flex;
  height: 70px;
  padding: 20px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1.406px solid rgba(255, 255, 255, 0.30);
}

.buttonContainerDisable {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, #808080);
}

.buttonContainer {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 22.489px;
  background: var(--Style, linear-gradient(270deg, #71BBFF 0%, #4048FF 100%));
}

.continue {
  color: var(--ffffff, #FFF);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

input {
  outline: none;
}
</style>
