import { setCookie } from '@/common/utils';
import { reactive } from 'vue';
import { K_TOKEN } from '../common/constant';

const saveToken = () => {
    setCookie(K_TOKEN, JSON.stringify({
        token: $user.token,
        account: $user.account,
        is_miner: $user.isMiner,
        is_new_user: $user.isNewUser,
        loginWithWallet: $user.loginWithWallet
    }), 1);
}

export const $user = reactive({
    token: "",
    account: "",
    isMiner: false,
    isNewUser: false,
    isLogined: false,
    loginWithWallet: false,
    private_key_backup: false,

    agent: "",
    avatar: "", 
    avatarPath: "",
    email: "",
    discord: "",
    ins: "",
    tg: "",
    twitter: "",
    nickname: "",
    balance: 0,
    updateUserInfo(user: any) {
        if (user) {
            this.agent = user.agent;
            this.avatar = user.avatar;
            this.avatarPath = user.full_path_avatar;
            this.email = user.email;
            this.ins = user.ins;
            this.tg = user.tg;
            this.twitter = user.twitter;
            this.discord = user.discord;
            this.nickname = user.nickname;
            this.private_key_backup = user.private_key_backup;
        }
    },
    updateAccount(account: string) {
        this.account = account.toLowerCase();
    },
    updateToken(token: string) {
        this.token = token;
    },
    updateLoginWithWallet(loginWithWallet: boolean) {
        this.loginWithWallet = loginWithWallet;
        saveToken();
    },
    updateUserLogin(login: any) {
        if (login?.address) {
            this.updateAccount(login.address)
        }

        this.token = login.token;
        this.isMiner = login.is_miner;
        this.isNewUser = login.is_new_user;
        if (this.account && this.token) {
            this.isLogined = true;
        } else {
            this.isLogined = false;
        }
        saveToken();
    },
    updateBalance(balance: any) {
        this.balance = balance;
    }
})

export const $context = reactive({
    chainId: "",
    chainNetwork: {
        rpcTarget: "",
        backendAPI: ""
    },
    web3: null,
    language: "en",
    contractVersions: new Map(),
    updateChainId(chainId: string) {
        this.chainId = chainId;
    },
    updateChainNetwork(network: any) {
        this.chainNetwork = network;
    },
    updateLanguage(language: string) {
        this.language = language;
    },
    updateWeb3(web3: any) {
        this.web3 = web3;
    },
    updateContractVersions(versions: any) {
        this.contractVersions = versions;
    }
})

export const pageConfig = reactive({
    menu: "home",
    yellowBg: false,
    updateMenu(menu: any) {
        if (menu) {
            this.menu = menu;
        }
    },
    updateYellowBg(isYellow: boolean) {
        this.yellowBg = isYellow;
    }
})