<template>
  <div id="root">
    <div class="h-full">
      <div v-if="showOnboardingDialog"></div>
      <div v-else-if="maintenanceStatus"></div>
      <div v-else>
        <HeaderView />
      </div>

      <div class=" relative">
        <RouterView />
        <OnboardingView :showOnboarding="showOnboardingDialog" />
        <el-drawer v-model="showDrawer" :with-header="false" direction="rtl" :z-index="2020"
          class="mainContainer max-w-lg mt-24 lg:mt-0 !w-full md:!w-6/12 ">

          <AccountCenter />
        </el-drawer>
        <FooterView class="" />
      </div>
    </div>

    <Chatbot />
    <WelcomeModal v-show="showWelcome" />
    <SwapDetailsModal v-show="showSwapDetails" />
  </div>
</template>

<script lang="ts">
import HeaderView from "./components/Header.vue";
import FooterView from "./components/Footer.vue";
import AccountCenter from "./components/AccountCenter.vue";
import Chatbot from "./components/Chatbot.vue";

import {
  getCookie,
  getCurrentNetworkConfig,
  isMainnet,
  setCookie,
} from "./common/utils";
import { $context, $user, pageConfig } from "./store";
import { chainId, K_TOKEN } from "./common/constant";
import request from "./common/http";
import Web3 from "web3";
import { chainRpcMapping } from "./config/chains";
import { ElMessage } from "element-plus";
import WelcomeModal from "./components/WelcomeModal.vue";
import SwapDetailsModal from "./components/SwapDetailsModal.vue";
import OnboardingView from "./views/Onboarding.vue";

export default {
  components: {
    HeaderView,
    FooterView,
    AccountCenter,
    WelcomeModal,
    SwapDetailsModal,
    OnboardingView,
    Chatbot
  },
  data() {
    return {
      firstLogin: false,
      showDrawer: false,
      showWelcome: false,
      pageConfig,
      showSwapDetails: false,
      showOnboardingDialog: false,
      maintenanceStatus: false,
      maintenanceCode: 0,
    };
  },
  mounted() {
    // (this as any).checkMaintenanceStatus();
    if ((this as any).maintenanceStatus) {
      (this as any).$router.push("/maintenance");
    }

    if (localStorage.getItem("_optional")) {
      (this as any).optional = true;
      (this as any).$router.push("/optional");
    }
    (this as any).addWalletListener();
    (this as any).loadUser();
    (this as any).init();
    if ($user.isLogined) {
      (this as any).initAfterLogin();
      (this as any).onBoarding();
    }
    if ((this as any).showOnboardingDialog) {
      (this as any).connectDialogVisible = true;
      (this as any).$router.push("/setup");
    }
  },

  watch: {
    $route(to, from) {
      // Check if the previous page's path starts with 'ambassador/'
      const wasOnAmbassadorPage = from.path.startsWith('/ambassador-rewards/');

      // Check if the current page's path starts with 'ambassador/'
      const isOnAmbassadorPage = to.path.startsWith('/ambassador-rewards');

      // Reload the page if the user navigated from 'ambassador/' to a different page
      if (wasOnAmbassadorPage && !isOnAmbassadorPage) {
        window.location.reload();
      }

    }
  },
  methods: {
    async checkMaintenanceStatus() {
      //this is to check backend connection and go to maintenance page if a 503 error is returned setup in http.ts
      const products = await request.get("/product/list", {
        product_type: "",
        can_buy_status: '',
        page: 1,
        limit: 10,
      });


    },
    async onBoarding() {
      const onboardingCheck: any = await request.get("/wallet/info");

      if ($user.loginWithWallet) {
        if (localStorage.getItem("newWalletUser")) {
          (this as any).showOnboardingDialog = true;

          (this as any).$router.push("/setup");
          // localStorage.removeItem("newWalletUser");
        } else {
          (this as any).showOnboardingDialog = false;
          if (localStorage.getItem("_optional")) {
            (this as any).$router.push("/optional");
          }
          // (this as any).$router.push("/");
        }
      } else if (
        onboardingCheck.password_is_set === false ||
        onboardingCheck.google_code_isset === false ||
        onboardingCheck.private_key_backup === false
      ) {
        (this as any).showOnboardingDialog = true;
        (this as any).$router.push("/setup");
      } else {
        (this as any).showOnboardingDialog = false;
      }
    },
    init() {
      const networkConfig = chainRpcMapping[chainId];

      if (!networkConfig) {
        (this as any).swichNetwork();
        return;
      }
      $context.updateChainNetwork(networkConfig);

      const web3 = new Web3(
        (window as any).ethereum || networkConfig.rpcTarget
      );
      $context.updateWeb3(web3);
    },

    initAfterLogin() {
      // need to do after login
      (this as any).getUserInfo();
      (this as any).getContractVersions();
    },
    async checkNetwork() {
      if ((window as any).ethereum) {
        const currentChainId = (this as any).getCurrentChainId();
        if (currentChainId != chainId) {
          await (this as any).swichNetwork();

          return (this as any).getCurrentChainId() == chainId;
        } else {
          return true;
        }
      }
      return false;
    },
    async swichNetwork() {
      try {
        const result = await (window as any).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainId }],
        });

        window.location.reload();
      } catch (switchError: any) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902 || switchError.code === -32603) {
          ElMessage.error((this as any).$t("app.please-add-compverse-chain"));
          // 弹出切换网络的提示框
          (window as any).ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [getCurrentNetworkConfig()],
            })
            .then(() => {
              console.log("Network switched.");
            })
            .catch((err: any) => {
              console.error("Network add error", err);
            });
        } else {
          ElMessage.error((this as any).$t("app.chain-switch-failed"));
        }
      }
    },
    loadUser() {
      const userCookie = getCookie(K_TOKEN);
      if (userCookie) {
        const userObj = JSON.parse(userCookie);

        $user.updateAccount(userObj["account"]);
        $user.updateLoginWithWallet(!!userObj["loginWithWallet"]);
        $user.updateUserLogin(userObj);
      }
    },
    async getUserInfo() {
      (this as any).showBackupDialog = false;
      const userInfo: any = await request.get("/user/info");
      $user.updateUserInfo(userInfo);
    },
    getCurrentChainId() {
      let currentChainId = (window as any).ethereum?.chainId;
      if (!currentChainId) {
        const chainId = isMainnet() ? "0x1a7b" : "0xd94";
        currentChainId = chainId;
      }
      return currentChainId;
    },
    addWalletListener() {
      if ((window as any).ethereum) {
        (window as any).ethereum.on("accountsChanged", (accounts: String) => {
          console.log("accountChanged", accounts);
          (this as any).logout();
        });

        (window as any).ethereum.on("chainChanged", (chainId: String) => {
          console.log("chainChanged", chainId);
        });

        $context.updateChainId((this as any).getCurrentChainId());
      }
    },
    toggleDrawer() {
      (this as any).showDrawer = !(this as any).showDrawer;
    },
    closeDrawer() {
      (this as any).showDrawer = false;
    },
    async getContractVersions() {
      const versionData = await request.get(`/contract_version/list`);
      const versions = (versionData as any)?.list;
      if (versions) {
        const map = new Map();
        versions.forEach((item: any) => {
          map.set(item.name, item.address);
        });
        $context.updateContractVersions(map);
        console.log("version updated.");
      } else {
        console.error("Get version data error.");
      }
    },
    async connectWallt() {
      if (!(window as any).ethereum) {
        ElMessage.warning((this as any).$t("app.no-wallet-detected"));
        return false;
      }
      const result = await (this as any).checkNetwork();

      if (!result) {
        return false;
      }

      if (!(window as any).ethereum?.isConnected()) {
        console.log("is not connected.");

        await (window as any).ethereum.request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
      }

      await (window as any).ethereum.request({ method: "eth_requestAccounts" });

      const accounts = await ($context.web3 as any).eth.getAccounts();
      const account = accounts[0];
      $user.updateAccount(account);
      return true;
    },
    async logout() {
      await request.post("/user/logout", {}).then(() => {
        window.location.reload();
      });
      setCookie(K_TOKEN, "", 1);
    },

    openSwapDetails() {
      (this as any).showSwapDetails = true;
    },
    closeSwapDetails() {
      (this as any).showSwapDetails = false;
    },
    closeWelcome() {
      (this as any).showWelcome = false;
      localStorage.removeItem("_ApplyBonus");
      localStorage.removeItem("onboardingPhase");
      localStorage.removeItem("newWalletUser");
      localStorage.removeItem("_new");
    },
    realOpenWelcome() {
      (this as any).showWelcome = true;
      (this as any).showOnboardingDialog = false;
    },
  },
};
</script>

<style>
.disabled {
  opacity: 0.5;
  pointer-events: none;
  position: fixed;
  top: 0;
}

.el-drawer {
  --el-drawer-bg-color: transparent !important;
  background-color: transparent !important;
}
</style>
