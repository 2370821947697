import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
import { ref } from 'vue'
import qs from 'qs'
import { getCookie, isMainnet, setCookie } from './utils'
import { K_TOKEN } from './constant'
import router from '../router';

// 创建axios实 例
// const isDev = process.env.NODE_ENV === 'development'
axios.defaults.baseURL = isMainnet() ? 'https://api-v1.araratglobal.io/api' : 'https://apiv1.andromedablack.com/api'
// axios.defaults.baseURL  = "https://api-v1.araratglobal.io/api";
axios.defaults.timeout = 60000
const loading: any = ref(false)
axios.interceptors.request.use((config: any) => {
    const tokenObj = getCookie(K_TOKEN);
    if (tokenObj) {
        config.headers['Authorization'] = `Bearer ${JSON.parse(tokenObj)['token']}`
    }

    config.headers['accept-language'] = localStorage.getItem('lang') || 'en';

    loading.value = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
    })

    return config
})

axios.interceptors.response.use(
    response => {
        const res = response.data
        if (response.status == 200) {
            loading.value.close()

            if (res && res.jsonrpc) {
                return res.result;
            }

            if (res.code == 200) {
                if (res.msg == 'Success' && res.data === "") {
                    return true;
                }
                return res.data
            } else if (res.code == 401) {
                ElMessage.error(res.msg)
                setCookie(K_TOKEN, "", 1)
                location.href = '/login'
            } else if (res.code == -400200) {
                // not set transaction password
                // location.href = '/wallet';
                if (res.msg != 'no data') {
                    ElMessage.error(res.msg);
                }
            } else if (res.code == -400203) {
                // no data
                // if (res.msg.toLowerCase() != 'no data') {
                //     ElMessage.error(res.msg);
                // }
                return null
            } else {
                ElMessage.error(res.msg)
                return Promise.reject(res.msg)
            }
            
            return res.data
        }
    },
    error => {
        loading.value.close()
        if (error && error.response) {
            if (error.response.status == 401) {
                setCookie(K_TOKEN, "", 1)
                location.href = '/login'
            }
    
            else if(error.response.status == 503){
        router.push('/maintenance');
            }
                else if(error.response.status == 5030){
        router.push('/maintenance');
            }
            
            
            error.message = error.response.data?.msg || 'Server error'
        } else {
            error.message = 'Failed to connect to the server'
        }

        return Promise.reject(error)
    }
)

const request = {
    get(url: string, params?: object) {
        return axios({ method: 'get', url, params })
    },
    post(url: string, data: object, method = 'post') {
        return axios({
            method: method,
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    postFormData(url: string, data: object, method = 'post') {
        let params = qs.stringify(data)
        return axios({
            method: method,
            url,
            data: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    put(url: string, data: object) {
        this.post(url, data, 'put')
    },
    upload(url: string, data: any, filename: string, params: any) {
        var formdata = new FormData()
        formdata.append('file', data, filename);
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                formdata.append(key, value as string);
            }
        }
        return axios({
            method: 'post',
            url,
            data: formdata,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    delete(url: string, params: object) {
        return axios({
            method: 'delete',
            url: url,
            data: params
        })
    }

}
export default request