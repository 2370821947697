<template>
  <div class="flex flex-row  gap-[20px] lg:gap-[40px]  w-full justify-center items-center h-full">
    <div class="hidden md:block">
      <router-link to="/ai-factory">
        <div class="textContainer text-[16px] 2xl:text-[20px] ">
          <div :class="currentMenu == 'aifactory' ? 'text-white activeText hoverEffect' : 'text-white  hoverEffect'">AI
            Factory
          </div>
        </div>
      </router-link>
    </div>
    <div class="hidden md:block ">
      <router-link to="/swap">
        <div class="textContainer text-[16px] 2xl:text-[20px] ">
          <div :class="currentMenu == 'swap' ? 'text-white activeText hoverEffect' : 'text-white  hoverEffect'">
            Withdraw</div>
        </div>
      </router-link>
    </div>
    <div class="hidden md:block ">
      <router-link to="/about">
        <div class="textContainer text-[16px] 2xl:text-[20px] ">
          <div :class="currentMenu == 'swap' ? 'text-white activeText hoverEffect' : 'text-white  hoverEffect'">
            About
          </div>
        </div>
      </router-link>
    </div>


    <!-- <div class="hidden md:block menu-block text-white ">
      <a class="text-baseline my-20 pb-10 md:px-3 whitespace-nowrap router-select font-bold cursor-default"
        href="javascript:void(0);">
        <div class="menu-bg hidden">
          <div class="max-w-con flex flex-row menu-content  text-[16px] lg:text-[20px] gap-10">
            <div class="basis-6/12">
              <div class="w-6/12 whitespace-pre-wrap">
                {{ $t("common.menu.resources.desc") }}
              </div>
            </div>
            <div class="textContainer font-bold  text-[16px] 2xl:text-[20px] ">
              <a href="https://www.araratglobal-blog.com/" target="_blank">
                <div class=" hoverEffect">
                  {{ $t("common.menu.resources.blog") }}
                </div>
              </a>

              <router-link to="/about">
                <div class="textContainer font-bold  text-[16px] 2xl:text-[20px]  mt-[10px] ">
                  <div class=" hoverEffect">

                    {{ $t("common.menu.resources.about") }}
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="textContainer text-[16px] 2xl:text-[20px] ">
          <div :class="currentMenu == 'about' ? 'text-white activeText hoverEffect  ' : 'text-white  hoverEffect '">
            {{
            $t("common.menu.about") }}</div>
        </div>
      </a>
    </div> -->
    <div class="hidden md:block cursor-pointer">
      <router-link to="/contact">
        <div class="textContainer text-[16px] 2xl:text-[20px]">

          <div :class="currentMenu == 'contact' ? 'text-white activeText hoverEffect  ' : 'text-white  hoverEffect '">
            {{ $t("common.menu.contact")
            }}</div>
        </div>
      </router-link>
    </div>
    <div class="hidden md:block cursor-pointer">
      <router-link to="/node-info">
        <div class="textContainer text-[16px] 2xl:text-[20px]">
          <div :class="currentMenu == 'nodeInfo' ? 'text-white activeText hoverEffect  ' : 'text-white  hoverEffect '">
            {{ $t("common.menu.nodes")
            }}</div>
        </div>
      </router-link>
    </div>
    <div class="hidden md:block cursor-pointer">
      <router-link to="/wallet">
        <div class="textContainer text-[16px] 2xl:text-[20px]">
          <div :class="currentMenu == 'wallet' ? 'text-white activeText hoverEffect  ' : 'text-white  hoverEffect '">
            {{ $t("common.menu.wallet")
            }}</div>
        </div>
      </router-link>
    </div>
  </div>

  <!-- <div
    class="grow flex flex-row items-center justify-center space-x-2 sm:space-x-1 md:space-x-1 text-[8px] sm:text-[8px] lg:text-[16px]"
    v-show="!menuStatus">
    <div class="flex flex-row">
      <div class="hidden sm:block ">
        <router-link to="/ai-factory">
          <div type="button" class="block px-4  w-full text-left colorful-hover-effect">
            AI Factory
          </div>
        </router-link>
      </div>

      <div class="hidden sm:block">
        <div class="relative inline-block text-left">
          <div class="group">

          </div>
        </div>
      </div>


      <div class="hidden sm:block ">
        <router-link to="/swap"
          class="text-baseline my-2 mb-10 px-3 whitespace-nowrap router-select colorful-hover-effect">
          <span :class="currentMenu == 'swap' ? 'colorful-text' : 'text-white'">{{ $t("common.menu.swap") }}</span>
        </router-link>
      </div>
      <div class="hidden sm:block menu-block">
        <a class="text-baseline my-2 pb-10 md:px-3 whitespace-nowrap router-select cursor-default"
          href="javascript:void(0);">
          <div class="menu-bg hidden">
            <div class="max-w-con flex flex-row menu-content text-lg font-bold gap-10">
              <div class="basis-6/12">
                <div class="w-6/12 whitespace-pre-wrap">
                  {{ $t("common.menu.resources.desc") }}
                </div>
              </div>
              <div class="basis-6/12 flex flex-col gap-4">
                <a href="https://www.araratglobal-blog.com/" target="_blank">
                  <div class="inline ml-5 colorful-hover-effect">
                    {{ $t("common.menu.resources.blog") }}
                  </div>
                </a>

                <router-link to="/about">
                  <div class="inline ml-5 colorful-hover-effect">
                    {{ $t("common.menu.resources.about") }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <span :class="currentMenu == 'about' ? 'colorful-text' : 'text-white'">{{ $t("common.menu.about") }}</span>
        </a>
      </div>
      <div class="hidden sm:block cursor-pointer">
        <router-link to="/contact"
          class="text-baseline my-2 mb-10 px-3 whitespace-nowrap router-select colorful-hover-effect">
          <span :class="currentMenu == 'contact' ? 'colorful-text' : 'text-white'">{{ $t("common.menu.contact")
            }}</span>
        </router-link>
      </div>
      <div class="hidden sm:block cursor-pointer">
        <router-link to="/node-info"
          class="text-baseline my-2 mb-10 px-3 whitespace-nowrap router-select colorful-hover-effect">
          <span :class="currentMenu == 'nodeInfo' ? 'colorful-text' : 'text-white'">{{ $t("common.menu.nodes")
            }}</span>
        </router-link>
      </div>
      <div class="hidden sm:block cursor-pointer">
        <router-link to="/wallet"
          class="text-baseline my-2 mb-10 px-3 whitespace-nowrap router-select colorful-hover-effect">
          <span :class="currentMenu == 'wallet' ? 'colorful-text' : 'text-white'">{{ $t("common.menu.wallet")
            }}</span>
        </router-link>
      </div>
    </div> -->

  <!-- <button v-show="unsupportedNetwork"
            class="sc-ifAKCX lnSKSd sc-gZMcBi sc-jzJRlG sc-gxMtzJ sc-dfVpRl btn-error-tips w-44">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="sc-kPVwWT egLcCq">
                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
            </svg>
            <p class="sc-hwwEjo khOVya">Wrong Network</p>
        </button> -->

  <!-- <div class="text-center pr-5 hidden  md:flex font-bold md:align-middle justify-center items-center text-[10px]"> -->
  <!-- <div class="hidden">

      <el-dropdown trigger="click">
        <img src="@/assets/images/icon-language.png" class=" h-3 w-3 lg:h-5 lg:w-5" alt="Switch Language" />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="(value, key) in languages" @click="switchLang(key, value)"
              :disabled="$i18n.locale == key">{{ value }}</el-dropdown-item>
          </el-dropdown-menu>
        </template>
</el-dropdown>
<div class="flex justify-center  items-center align-middle pt-[2px] pl-1">
  {{ currentLanguage }}
</div>
</div> -->

  <!-- <div class="text-center pr-5 hidden md:inline">
        <img src="@/assets/images/icon-wallet.png" class="cursor-pointer" style="width: 60px;" @click="" />
        </div> -->
  <!-- </div> -->
</template>

<script lang="ts">

import { pageConfig } from "@/store";
import {
  supportedLanguages,
  currentLanguageDesc,
  languagesDict,
  currentLanguage,
} from "../i18n/i18n";
export default {
  name: "MenuDesktop",
  props: {
    currentMenu: String,
    menuStatus: Boolean,
  },
  data() {
    return {
      //   unsupportedNetwork: false,
      isDropdownOpen: false,
      currentNetwork: {},
      languages: supportedLanguages,
      currentLanguageDesc: currentLanguageDesc(),
      currentLanguage: currentLanguage(),
      pageConfig,
    };
  },
  methods: {
    login() { },
    closeMenu() {
      (this as any).menuStatus = !(this as any).menuStatus;
    },
    switchLang(key: string, value: string) {
      (this as any).$i18n.locale = key;
      localStorage.setItem("lang", key);
      (this as any).currentLanguageDesc = value;
      (this as any).currentLanguage = languagesDict[key as keyof object];
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap');



.textContainer {
  color: #FFF;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

}

.activeText {
  font-weight: bold;
}

.hoverEffect:hover {
  background: linear-gradient(270deg, #71BBFF 0%, #4048FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;


}

.menu-content {
  position: relative;
  margin: auto;
  border-radius: 10px;
  width: 80%;
  max-width: 1260px;
  color: white;
}

.router-select {
  position: relative;
}

.router-select:hover .menu-bg,
.menu-bg>div:hover {
  display: flex;
}

/* .menu-bg {
  position: fixed;
  top: 91px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 2000;
  height: 200px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(25px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* background-color: #f6eae2; 
 } */
</style>