<template>
  <div class="cursor-pointer">
    <div class="fixed w-[640px] max-w-full h-auto right-0 md:right-[58px] bottom-0 rounded-[28px] z-[1001]"
      v-show="chatbotDialogFlag">
      <img src="@/assets/images/closechatbot.png" class="w-6 absolute top-4 right-12" @click="hideChatbot" />
      <div>
        <iframe class="chatIfram w-full h-full min-h-[627px] rounded-[28px]"
          src="https://kn1.fastpod.ai/chatbot/yJVNgtEnvFfkST1q" frameborder="0" allow="microphone">
        </iframe>
      </div>
    </div>
    <img src="@/assets/images/chatbot-icon.png" alt="chatbot-Logo"
      class="fixed w-[64px] right-[30px] md:right-[113px] bottom-[34px] z-[1001] " v-show="!chatbotDialogFlag"
      @click="showChatbotDialog" />
  </div>
</template>
<script lang="ts">
export default {
  name: "Chatbot",
  data() {
    return {
      chatbotDialogFlag: false,
    };
  },
  methods: {
    showChatbotDialog() {
      (this as any).chatbotDialogFlag = true;
    },
    hideChatbot() {
      (this as any).chatbotDialogFlag = false;
    }
  },
};
</script>

<style>
#dify-chatbot-bubble-button {
  background-color: #1C64F2 !important;
}
</style>
<style scoped>
@media only screen and (max-height: 668px) {
  .chatIfram {
    min-height: 500px;
  }
}
</style>