import * as RRMTokenABI from '../contracts/RRMTokenABI.json';
import * as eusdtTokenABI from '../contracts/eusdtTokenABI.json';
import * as eFilTokenABI from '../contracts/eFileTokenABI.json';
import * as eIOPointTokenABI from '../contracts/eIOPointTokenABI.json';
import * as usdtTokenABI from '../contracts/usdtTokenABI.json';
import * as rgpTokenABI from '../contracts/rgpTokenABI.json';
import * as ioPledgeABI from '../contracts/ioPledgeABI.json';
import * as PledgeABI from '../contracts/abi_pledge.json';
import * as eALEOTokenABI from '../contracts/eAleoABI.json';
// import * as depositABI from '../contracts/depositABI.json';
// import ERC20ABI from '../contracts/IERC20.json';
import ERC20ABI from '../contracts/efilChange.json';
import RRMTokenV2 from '../contracts/abi_rrmtoken_5355.json';
import RRMTokenV3 from '../contracts/abi_rrmtoken_v3.json';
import AMBABI from '../contracts/abi_AMB.json';
import SUBABI from '../contracts/sub_abi.json';

import eUSDTExchangeABI from '../contracts/eUSDTExchangeABI.json';
// 当前支持的网络配置
export const chainRpcMapping: { [key: string]: any } = {
  "0x1a7b": {
    name: "Compverse Mainnet",
    chainName: "Compverse",//Chain.Ethereum,
    rpcTarget: "https://rpc.compverse.io",
    backendAPI: "https://api-v1.araratglobal.io",
    staticUrl: "https://static.araratglobal.io",
    icon: "https://docs.compverse.io/images/logo.png",
  
    contracts: {
            ioPledge:{
        address: "0xC54D7A8Ef8bb487FDFE768900A8Fe2a3Ac1f2426",
        ABI:   ioPledgeABI
      },
            eALEO:{
                           currency: "eAleo",
        address:'0xC20B8D042A4575C8baD263a6875EcA586844D00D',
        ABI:   JSON.parse(eALEOTokenABI.result)
      },
                       eUSDTExchange: {
        address: "0x7A390A8E71d0A717936477d8e68ED536Cf2Ff6dc",
        ABI: eUSDTExchangeABI
      },
                       eIOPoint: {
        address: "0x6673CCB8D680e47615eC19e45C42Cdf1CAFCfAc6",
                ABI: JSON.parse(eIOPointTokenABI.result)
      },
           eUSDT: {
            currency: "eUSD",
        address: "0x8376d8e6f7281a0d386bbdf81ebccf02f84c1933",
        ABI: JSON.parse(eusdtTokenABI.result)
      },
      eFil: {
        address: "0x5C4772038757Ae9603737f16BA2e0D2ff06e545d",
        ABI: JSON.parse(eFilTokenABI.result)
      },
      rgp: {
        address: "0x3975C90161fa9c87f8E77A3d2D28dacB568F58bB",
        ABI: JSON.parse(rgpTokenABI.result)
      },
      usdt: {
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        ABI: JSON.parse(usdtTokenABI.result)
      },
      rrmToken: {
        address: "0xeC017920cD5Ba44B0C2fD2c9eddDFE9c4c6aE7b8",
        ABI: RRMTokenV3,
        abiMap: {
          "v1": JSON.parse(RRMTokenABI.result),
          "v2": RRMTokenV2,
          "v3": RRMTokenV3
        }
      },
      deposits: {
        address: "0xe28cDf5cDfc41BFDB4316A024a8063a00cD32ef0",
        ABI: PledgeABI
      },
      erc20: {
        address: "0x9CB416222Dce63558526420ee8F4265EB64a7d34",
        ABI: ERC20ABI
      },
      sub: {
        address: "0x4cF76e2ae4f7Ca6c91eb906E8F3b9Eaec09e475C",
        ABI: SUBABI
      },
      AMB: {
        address: "0x17a0Ee1A6922F9795fc00e30503f6dC8d9259850",
        ABI: AMBABI
      }
    }
  },
  "0xd94": {
    name: "Compverse(TESTNET)",
    chainName: "Compverse",
    rpcTarget: "https://rpcpeg.compverse.io",
    backendAPI: "https://backendv1.andromedablack.com",
    staticUrl: "https://staticv1.andromedablack.com",
    icon: "https://docs.compverse.io/images/logo.png",
    contracts: {
      ioPledge:{
        address: "0xC54D7A8Ef8bb487FDFE768900A8Fe2a3Ac1f2426",
        ABI:   ioPledgeABI
      },
      eALEO:{
        currency: "eAleo",
        address:'0x3b494098eeb30B02B2b4C576839BE8B432D0d6c7',
        ABI:   JSON.parse(eALEOTokenABI.result)
      },
      eUSDTExchange: {
        currency: "eUSDT",
        address: "0x332799376D5FE3Dc695FDe09B7FE2C6DBD7F9cB8",
        ABI: eUSDTExchangeABI
      },
      eIOPoint: {
        address: "0xDE6D4E041b48C3f58F7f37B3B7F994C63f8B8455",
        ABI: JSON.parse(eIOPointTokenABI.result)
      },
           eUSDT: {
                    currency: "eUSD",
        address: "0xBf58DABe6A6Bf73c15D66e3f381949a35D6e0F4D",
        ABI: JSON.parse(eusdtTokenABI.result)
      },
      rgp: {
        address: "0xb0c5c030071B8e6040C9497E3F2Ba76Fc7Cd1cc7",
        ABI: JSON.parse(rgpTokenABI.result)
      },
      eFil: {
        address: "0x8bD2b491B28C2984C80940B2407CAfb1Cbb05951",
        ABI: JSON.parse(eFilTokenABI.result)
      },
      usdt: {
        address: "0x91E952AEF36E9fFce2E2518fF75EF150d8620a7a",
        ABI: JSON.parse(usdtTokenABI.result)
      },
      rrmToken: {
        address: "0x8cbc82bf0709b2C7c8c8BEB25AE1D55B71D6356a",
        ABI: RRMTokenV3,
        abiMap: {
          "v1": JSON.parse(RRMTokenABI.result),
          "v2": RRMTokenV2,
          "v3": RRMTokenV3
        }
      },
      deposits: {
        address: "0x576675c6cf36562C695c2c9B4bDdB9e8e9255996",
        ABI: PledgeABI
      },
      erc20: {
        address: "0x1C431f6F82FB873976f012B7b9daf825F8134AF7",
        ABI: ERC20ABI
      },
      sub: {
        address: "0xef81BcA7e209733faf030a062C7Ee0f0ADBaF081",
        ABI: SUBABI
      },
      AMB: {
        address: "0x6fcbcCa4112d5ee4C443fEa03556DE283aB19ca9",
        ABI: AMBABI
      }
    }
  }
};