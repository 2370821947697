export default [
	{
		"inputs": [],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint8",
				"name": "package",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "daysToRent",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "paymentMethod",
				"type": "string"
			},
			{
				"internalType": "bool",
				"name": "autoRefund",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "nextRewardRate",
				"type": "uint256"
			}
		],
		"name": "purchaseOrder",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "orderIndex",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "autoRefundValue",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "nextRate",
				"type": "uint256"
			}
		],
		"name": "setUserAutoRefund",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_efilTokenAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_depositHolder",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_rewardDistribution",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_technicalAccount",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "package",
				"type": "uint8"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "refunded",
				"type": "bool"
			},
			{
				"indexed": false,
				"internalType": "string",
				"name": "paymentMethod",
				"type": "string"
			}
		],
		"name": "RefundRequested",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "addressesWithDeposits",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "checkIfNewAddress",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "currentDay",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositHolder",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "eFilToken",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardDistribution",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardInterval",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "technicalAccount",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "totalClaimedRewards",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "userOrders",
		"outputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint8",
				"name": "package",
				"type": "uint8"
			},
			{
				"internalType": "uint256",
				"name": "startDate",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "endDate",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "autoRefund",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "refunded",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "orderTermFinished",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "lastRewardTime",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "rewardRate",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "totalClaimedRewards",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "paymentMethod",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "nextRewardRate",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]