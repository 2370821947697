<template>
    <div v-if="showSuccess">
        <div class="modal-box-black">
            <div
                class="w-10/12 md:9/12 lg:w-5/12 xl:w-5/12  2xl:w-4/12 3xl:3/12  h-fit   flex flex-col  z-20  bg-white">
                <div class="text-[24px] md:text-[32px]  font-bold m-6 text-center">
                    <div>
                        {{ $t("google.authentication-setup") }}</div>
                    <div> {{ $t("common.successful") }} </div>
                </div>
                <div class="w-10/12 flex justify-center h-8 items-center border mb-4  mx-10  cursor-pointer"
                    @click="($parent as any).closeGoogleAuthModal()">
                    <span class="font-bold">{{ $t("common.continue") }}</span>
                </div>

            </div>
        </div>
    </div>
    <div v-else-if="!showSuccess">
        <div class="modal-box-black">
            <div class="w-10/12 md:9/12 lg:w-5/12 xl:w-5/12  2xl:w-4/12 3xl:3/12 h-fit flex flex-col  z-20  bg-white">
                <div class="text-[24px] md:text-[32px]  font-bold m-6 text-center">
                    {{ $t("google.Authentication-setup-failed") }} </div>

                <div class="text-[24px] md:text-[20px] font-bold flex justify-center text-center">
                    {{ $t("common.try.again") }}
                    <br>
                    {{ $t("common.contact") }}
                </div>



                <div class="w-10/12 flex justify-center h-8 items-center border mt-6 mx-10 mb-4  cursor-pointer"
                    @click="($parent as any).closeGoogleAuthModal()">
                    <span class="font-bold">{{ $t("common.continue") }}</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts">

export default {
    name: "GoogleAuthModal",
    props: {
        result: {
            type: Boolean
        }
    },
    data() {
        return {
            showSuccess: false,
        };
    },
    mounted() {
        if ((this as any).result) {
            (this as any).showSuccess = true;
        }
    },
    methods: {
    },
};
</script>
<style scoped></style>