<template>
  <div class="modal-box-black">
    <div class="w-full h-full flex justify-center items-center z-20 top-0 left-0">
      <div
        class="mx-4 md:mx-0 px-1 md:px-14 py-1 md:py-6 w-full w-[90%] md:w-[60%] h-fit border border-[#DFDFDF] bg-[#FFF9F9] rounded-[1.83rem] relative">
        <img src="@/assets/images/icon-close.png" alt="close" @click="($parent as any).closeSwapDetailsS()"
          class="w-4 absolute top-6 md:top-[45px] right-6 md:right-[2.875rem] cursor-pointer">

        <div class="text-[24px] md:text-[32px] colorful-text font-bold  w-[100%] flex justify-center">
          {{ $t('swap') }}</div>

        <div class="text-[24px] md:text-[32px] colorful-text font-bold flex  w-[100%] justify-center">
          {{ $t('common.applied') }}
        </div>

        <div class="font-bold text-[14px] md:text-[18px] mt-4 m-10 mb-0 text-center text-red-700">
          <!-- ***If you leave the login process you will need to re-apply the code*** -->
        </div>
        <el-button class="w-full border mt-10 mb-2" size="large" @click="($parent as any).closeSwapDetails()">
          <span class="font-bold"> {{ $t('common.continue') }}</span>
        </el-button>
        <div class="flex justify-center mt-4 px-4">

        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

export default {
  name: "SwapDetailsModal",
  data() {
    return {

    };
  },
  mounted() {
    (this as any).$parent.closeSwapDetails();
  },
  methods: {



  },
};
</script>
<style scoped></style>
