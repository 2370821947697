import { ElMessage } from "element-plus";
import JSEncrypt from 'jsencrypt'
import { chainId, RSA_PUBLIC_KEY } from './constant';

// 把 '2023-05-01 00:00:00' 格式的时间转换为日期对象
// 如果直接用 new Date，在 iOS 15 的 MetaMask 浏览器上会解析失败
export function dateStrToObject(dateStr: string) {
  if (dateStr) {
    const dateParts = dateStr.split(' '); // 将日期字符串拆分为日期和时间两部分
    const [year, month, day] = dateParts[0].split('-'); // 将日期部分拆分为年、月、日三部分
    const [hour, minute, second] = dateParts[1].split(':'); // 将时间部分拆分为时、分、秒三部分
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hour), parseInt(minute), parseInt(second)); // 创建日期对象
    return date;
  }
  return new Date();
}

export function toDateFormatStr(date: any) {
  if (date) {
    const newDateStr = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    return newDateStr;
  }
  return "";
}

export function timestampToDateTime(value: string, defaultValue: string) {
  if (value) {
    let date = new Date(parseInt(value));
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    let MMStr = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    let dStr = d < 10 ? "0" + d : d;
    let h = date.getHours();
    let hStr = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    let mStr = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    let sStr = s < 10 ? "0" + s : s;
    return y + "-" + MMStr + "-" + dStr + " " + hStr + ":" + mStr + ":" + sStr;
  }
  return defaultValue ? defaultValue : ""
}

export function timestampToDate(value: string, defaultValue: string) {
  if (value) {
    let date = new Date(parseInt(value));
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    let MMStr = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    let dStr = d < 10 ? "0" + d : d;
    return y + "-" + MMStr + "-" + dStr;
  }
  return defaultValue ? defaultValue : ""
}

export function millsecondToDate(value: string, defaultValue: string) {
  if (value) {
    return timestampToDate((parseInt(value) * 1000).toString(), defaultValue);
  }
  return defaultValue;
}

export function dateCount(endTime: string, nowDate?: any){
  // 现在时间
  var now: any = nowDate;
  if (!now) {
    now = new Date();
  }
  //截止时间
  var until: any = dateStrToObject(endTime);
  // 计算时会发生隐式转换，调用valueOf()方法，转化成时间戳的形式
  var days = (until- now)/1000/3600/24;
  // 下面都是简单的数学计算
  var day = Math.floor(days);
  var hours = (days - day)*24;
  var hour = Math.floor(hours);
  var minutes = (hours - hour)*60;
  var minute = Math.floor(minutes);
  var seconds = (minutes - minute)*60;
  var second = Math.floor(seconds);
  var back = day + ' d ' + hour + ' h ' + minute + ' m '; //  + second + ' s'
  return back;
}

export function timestampToTime(value: string, defaultValue: string) {
  if (value) {
    let date = new Date(parseInt(value));
    let h = date.getHours();
    let hStr = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    let mStr = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    let sStr = s < 10 ? "0" + s : s;
    return hStr + ":" + mStr + ":" + sStr;
  }
  return defaultValue ? defaultValue : ""
}

export function millsecondToTime(value: string, defaultValue: string) {
  return timestampToTime((parseInt(value) * 1000).toString(), defaultValue);
}

export function getServerDate() {
  var req = new XMLHttpRequest();
  req.open('GET', window.location.toString(), false);
  req.send(null);
  return req.getResponseHeader('Date');
}

export function timestampToDay(value: string, defaultValue: string) {
  if (value) {
    let date = new Date(parseInt(value));
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    let MMStr = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    let dStr = d < 10 ? "0" + d : d;
    return y + "-" + MMStr + "-" + dStr;
  }
  return defaultValue ? defaultValue : ""
}

export function isMobile() { 
  return ('ontouchstart' in document.documentElement); 
}

export function isMainnet() {
  return window.location.hostname === 'www.araratglobal.io' || window.location.hostname === 'araratglobal.io' || window.location.hostname === 'gpu.powermeta.ai';
}

export const resolveWalletByProvider = (provider: any) => {
  let resolved = "MetaMask";

  // TODO: we should persist wallet selection somewhere
  if (!provider) {
    resolved = "MetaMask"; //default wallet
  } else if (provider?.isMetaMask) {
    resolved = "MetaMask";
  } else if (provider?.isCoinbaseWallet) {
    resolved = "Coinbase";
  } else if (provider?.wallet?._providerUrl?.href?.includes("sollet")) {
    resolved = "Sollet";
  } else if (provider?.wallet) {
    resolved = "Phantom";
  } else if (
    provider?.chainId === "0x61" ||
    provider?.chainId?.indexOf("Binance")
  ) {
    resolved = "BinanceSmartChain";
  } else if (provider?.isMewConnect || provider?.isMEWConnect) {
    resolved = "MyEtherWallet";
  } else {
    console.warn("Unresolved wallet", provider);
  }
  return resolved;
};

/**
 * 根据host返回根域名
 * @param  {[string]} host [window.location.host]
 * @return {[string]}      [如果不是域名则返回IP]
 */
export const getDomain = (host: String) => {
  host = host.split(':')[0]
  return isNaN(host.substring(host.lastIndexOf('.')) as any) ? 
    host.substring(host.substring(0, host.lastIndexOf('.')).lastIndexOf('.') + 1) : host
}

export const getCookie = (cookieName: String) => {
  let cookie: {[key: string]: any} = {};
  document.cookie.split(';').forEach(function(el) {
    let [key, value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName as string];
}

export const setCookie = (cName: String, cValue: String, expDays: any) => {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; domain=" + getDomain(document.domain) + "; " + expires + "; path=/";
}

export const toNFix = (number: string, decimal: number) => {
  if (number) {
    return +parseFloat(number).toFixed(decimal);
  }
  return 0;
}

// 保留 n 位小数不四舍五入
//num：要格式化的字段
//decimal：要保留的小数位数
export function formatDecimal(num: any, decimal: number) {
  num = num.toString()
   let index = num.indexOf('.')
   if (index !== -1) {
       num = num.substring(0, decimal + index + 1)
   } else {
       num = num.substring(0)
   }
   return +(parseFloat(num).toFixed(decimal))
}

export function GetQueryString(name: string) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

export async function swichNetwork() {
  try {
    await (window as any).ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
    window.location.reload();
  } catch (switchError: any) {
    console.log("error", switchError);
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      console.log("add this chain id");
      ElMessage({
        showClose: true,
        message: "Please add compverse chain.",
        type: "error",
      });
    }
  }
}

export function formatAmount(amount: any) {
  if (amount) {
    // return +parseFloat(amount).toFixed(8);
    return +formatDecimal(amount, 8);
  }
  return 0;
}

export function timeDown(time: any) {
  if (time > 0) {
    //判断剩余倒计时时间如果大于0就执行倒计时否则就结束
    // var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
    var hh =
      Math.floor((time / 1000 / 60 / 60) % 24) < 10
        ? "0" + Math.floor((time / 1000 / 60 / 60) % 24)
        : Math.floor((time / 1000 / 60 / 60) % 24) + "";
    var mm =
      Math.floor((time / 1000 / 60) % 60) < 10
        ? "0" + Math.floor((time / 1000 / 60) % 60)
        : Math.floor((time / 1000 / 60) % 60).toString();
    var ss =
      Math.floor((time / 1000) % 60) < 10
        ? "0" + Math.floor((time / 1000) % 60)
        : Math.floor((time / 1000) % 60).toString();
    return `${hh}:${mm}:${ss}`;
  }
  return ""
}

export function getEncryptStr(str: any) {
  if (!str) {
    return "";
  }
  
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(RSA_PUBLIC_KEY);

  const randomStr = Math.floor(Math.random() * 1000000) + 1;

  let pwdData = {
      "password": str,
      "nonce": `${randomStr}`,
      "timestamp": `${Math.floor(Date.now() / 1000)}`
  }

  return jsencrypt.encrypt(JSON.stringify(pwdData));
}

export function getCurrentNetworkConfig() {
  if (isMainnet()) {
    return {
      chainId: '0x1a7b',
      chainName: 'Compverse Mainnet',
      nativeCurrency: {
        name: 'CVERSE',
        symbol: 'CPV',
        decimals: 18
      },
      rpcUrls: ['https://rpc.compverse.io'],
      blockExplorerUrls: ['https://scan.compverse.io']
    };
  } else {
    return {
      chainId: '0xd94',
      chainName: 'Compverse Testnet',
      nativeCurrency: {
        name: 'CVERSE',
        symbol: 'CPV',
        decimals: 18
      },
      rpcUrls: ['https://rpcpeg.compverse.io'],
      blockExplorerUrls: ['https://pegasus.compverse.io']
    }
  }
}